export default [
    {
        name: 'clean',
        title: 'Cleanse',
        maxResults: {
            essential: 1,
            core: 1,
            advanced: 1
        }
    },
    {
        name: 'hydrate',
        title: 'Hydrate',
        maxResults: {
            essential: 1,
            core: 1,
            advanced: 1
        }
    },
    {
        name: 'treat',
        title: 'Treat',
        maxResults: {
            essential: 1,
            core: 1,
            advanced: 1
        }
    },
    {
        name: 'serum',
        title: 'Serum',
        group: 'treat',
        maxResults: {
            essential: 1,
            core: 1,
            advanced: 1
        }
    },
    {
        name: 'toner',
        title: 'Toners/Exfoliating treatments',
        group: 'treat',
        maxResults: {
            essential: 0,
            core: 1,
            advanced: 1
        }
    },
    {
        name: 'eye',
        title: 'Eye treatment',
        maxResults: {
            essential: 0,
            core: 0,
            advanced: 1
        }
    },
    {
        name: 'moisturize',
        title: 'Moisturize',
        maxResults: {
            essential: 0,
            core: 1,
            advanced: 1
        }
    },
    {
        name: 'moisturizer',
        title: 'Moisturizer',
        group: 'moisturize',
        maxResults: {
            essential: 1,
            core: 1,
            advanced: 1
        }
    },
    {
        name: 'oil',
        title: 'Oil',
        group: 'moisturize',
        maxResults: {
            essential: 1,
            core: 1,
            advanced: 1
        }
    },
    {
        name: 'spf',
        title: 'SPF',
        maxResults: {
            essential: 0,
            core: 1,
            advanced: 1
        }
    },
    {
        name: 'alsotry',
        title: 'Also try...',
        maxResults: {
            essential: 0,
            core: 0,
            advanced: 0
        }
    }
];
