import './scss/recipe-builder.scss'
import rbSettings from './config/rb_settings.js'
import rbAnswerData from './config/recipe_answer_data.js'
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './components/data-entry/App';
import TheAboutYou from './components/data-entry/TheAboutYou'
import TheKnowledge from './components/data-entry/TheKnowledge'
import TheSkinGoals from './components/data-entry/TheSkinGoals'
import TheEyeGoals from './components/data-entry/TheEyeGoals'
import TheSkinType from './components/data-entry/TheSkinType'
import TheSensitivity from './components/data-entry/TheSensitivity'
import ThePregnancy from './components/data-entry/ThePregnancy'
import TheResults from './components/results/TheResults'

window.dataLayer = window.dataLayer || [];

window.recipeDownload = {
    background: require("@/assets/recipe-download-bg.webp"),
    errorIcon: require("@/assets/recipe-download-error.webp"),
    errorIconWhite: require("@/assets/recipe-download-error-white.webp"),
    infoIcon: require("@/assets/recipe-download-info.webp"),
    infoIconWhite: require("@/assets/recipe-download-info-white.webp"),
    superSolutions: require("@/assets/ss-logo.webp"),
    sunIcon: require("@/assets/sun.webp"),
    sunIconWhite: require("@/assets/recipe-download-info-sun-white.webp"),
    moonIcon: require("@/assets/moon.webp"),
    moonIconWhite: require("@/assets/recipe-download-info-moon-white.webp")
};

Vue.config.productionTip = false

Vue.use(VueRouter);

const defaultProducts = 'oatc,hyal,poly,phat,cafe,omem,dspf';

const routerArray = [
    { name: 'about', path: '/', component: TheAboutYou },
    { name: 'knowledge', path: '/', component: TheKnowledge },
    { name: 'skin', path: '/:knowledge', component: TheSkinGoals },
    { name: 'eye', path: '/:knowledge/:skin', component: TheEyeGoals },
    { name: 'type', path: '/:knowledge/:skin/:eye', component: TheSkinType },
    { name: 'sensitivity', path: '/:knowledge/:skin/:eye/:type', component: TheSensitivity },
    { name: 'pregnancy', path: '/:knowledge/:skin/:eye/:type/:sensitivity', component: ThePregnancy },
    { name: 'results', path: '/:knowledge/:skin/:eye/:type/:sensitivity/:pregnancy', component: TheResults } 
]

const router = new VueRouter({
    method: 'hash',
    routes: routerArray
});

const root = document.getElementById('app');

new Vue({
    data: {
        textNext: rbSettings.textNext,
        textBack: rbSettings.textBack,
        textHelp: rbSettings.textHelp,
        textSkip: rbSettings.textSkip,
        textAboutTitle: rbSettings.textAboutTitle,
        textAboutNameTitle: rbSettings.textAboutNameTitle,
        textAboutGenderTitle: rbSettings.textAboutGenderTitle,
        textGenderMale: rbSettings.textGenderMale,
        textGenderFemale: rbSettings.textGenderFemale,
        textGenderPreferNotToSay: rbSettings.textGenderPreferNotToSay,
        textGoalTitle: rbSettings.textGoalTitle,
        textGoalYourConcern: rbSettings.textGoalYourConcern,
        textGoalOtherTitle: rbSettings.textGoalOtherTitle,
        textGoalSelectMore: rbSettings.textGoalSelectMore,
        textAccountTitle: rbSettings.textAccountTitle,
        textAccountSignUp: rbSettings.textAccountSignUp,
        textAccountAlreadyHaveAccount: rbSettings.textAccountAlreadyHaveAccount,
        textAccountNoThanks: rbSettings.textAccountNoThanks,
        textAccountNote: rbSettings.textAccountNote,
        textEmailHi: rbSettings.textEmailHi,
        textEmailText: rbSettings.textEmailText,
        textEmailCheckbox: rbSettings.textEmailCheckbox,
        textEmailContinue: rbSettings.textEmailContinue,
        textEmailEmailAddress: rbSettings.textEmailEmailAddress,
        textEmailSkip: rbSettings.textEmailSkip,
        textAlgorithmTitle: rbSettings.textAlgorithmTitle,
        textAlgorithmText: rbSettings.textAlgorithmText,
        klaviyoId: rbSettings.klaviyoId,
        emailTitle: rbSettings.emailTitle,
        emailText: rbSettings.emailText,
        emailMarketing: rbSettings.emailMarketing,
        emailOptInCopy: rbSettings.emailOptInCopy,
        emailMandatory: rbSettings.emailMandatory,
        ssQuestions: rbSettings.ssQuestions,
        registrationDisabled: rbSettings.registrationDisabled,
        customerId: null,
        customerLoggedIn: null,
        customerName: null,
        customerEmail: null,
        customerGender: null,
        naApiRequestTransformerBaseUrl: rbSettings.naApiRequestTransformerBaseUrl,
        datasetData: rbAnswerData,
        defaultProducts: defaultProducts,
        triggerAnalytics: false,
        essentialDisabled: rbSettings.essentialDisabled,
        textPersonalized: rbSettings.textPersonalized,
        textSkinRoutine: rbSettings.textSkinRoutine,
        textFor: rbSettings.textFor,
        textChooseRoutine: rbSettings.textChooseRoutine,
        textEssential: rbSettings.textEssential,
        textCore: rbSettings.textCore,
        textAdvanced: rbSettings.textAdvanced,
        textYour: rbSettings.textYour,
        textRoutine: rbSettings.textRoutine,
        textMerch: rbSettings.textMerch,
        textOosText: rbSettings.textOosText,
        textAdded: rbSettings.textAdded,
        textAddToBasket: rbSettings.textAddToBasket,
        textShare: rbSettings.textShare,
        textDownload: rbSettings.textDownload,
        textEmail: rbSettings.textEmail,
        textUnlockMembers: rbSettings.textUnlockMembers,
        textGoToProfile: rbSettings.textGoToProfile,
        textSignUp: rbSettings.textSignUp,
        textEditAnswers: rbSettings.textEditAnswers,
        textMoreInfo: rbSettings.textMoreInfo,
        textFullProductDetail: rbSettings.textFullProductDetail,
        textWhenToApply: rbSettings.textWhenToApply,
        textTargets: rbSettings.textTargets,
        textChangeConcern: rbSettings.textChangeConcern,
        textClose: rbSettings.textClose,
        textSkincareApproach: rbSettings.textSkincareApproach,
        textMainConcern: rbSettings.textMainConcern,
        textMainConcernSs: rbSettings.textMainConcernSs,
        textOtherGoals: rbSettings.textOtherGoals,
        textEyeConcern: rbSettings.textEyeConcern,
        textSkinType: rbSettings.textSkinType,
        textSensitiveSkin: rbSettings.textSensitiveSkin,
        textPregnant: rbSettings.textPregnant,
        textSavingPrompt: rbSettings.textSavingPrompt,
        textSaveAndUpdate: rbSettings.textSaveAndUpdate,
        textCancel: rbSettings.textCancel,
        textPleaseSelect: rbSettings.textPleaseSelect,
        textPleaseSelectAnother: rbSettings.textPleaseSelectAnother,
        textMerchSkip: rbSettings.textMerchSkip,
        textMerchAddRoutine: rbSettings.textMerchAddRoutine,
        recipeDownload: rbSettings.recipeDownload,
        recipeDownloadInstructions: rbSettings.recipeDownloadInstructions,
        heroTitleBold: rbSettings.heroTitleBold,
        heroTitleNormal: rbSettings.heroTitleNormal,
        heroTextarea: rbSettings.heroTextarea,
        tieredDiscounts: rbSettings.tieredDiscounts,
        essentialDiscount: rbSettings.essentialDiscount,
        coreDiscount: rbSettings.coreDiscount,
        advancedDiscount: rbSettings.advancedDiscount,
        essentialSavingsText: rbSettings.essentialSavingsText,
        essentialCartPrompt: rbSettings.essentialCartPrompt,
        essentialButtonText: rbSettings.essentialButtonText,
        coreSavingsText: rbSettings.coreSavingsText,
        coreCartPrompt: rbSettings.coreCartPrompt,
        coreButtonText: rbSettings.coreButtonText,
        advancedSavingsText: rbSettings.advancedSavingsText,
        advancedCartPrompt: rbSettings.advancedCartPrompt,
        advancedButtonText: rbSettings.advancedButtonText,
        free: rbSettings.free,
        signoffImage: rbSettings.signoffImage,
        freeProductText: rbSettings.freeProductText,
        freeProductCoreInclude: rbSettings.freeProductCoreInclude,
        deliveryBannerEnabled: rbSettings.deliveryBannerEnabled,
        deliveryBannerFreeStandardQualified: rbSettings.deliveryBannerFreeStandardQualified,
        deliveryBannerFreeExpressQualified: rbSettings.deliveryBannerFreeExpressQualified,
        step: rbSettings.step,
        clean: rbSettings.stepClean,
        hydrate: rbSettings.stepHydrate,
        treat: rbSettings.stepTreat,
        eye: rbSettings.stepEye,
        moisturize: rbSettings.stepMoisturize,
        spf: rbSettings.stepSpf,
        alsotry: rbSettings.stepAlsotry
    },
    router,
    el: root,
    mounted() {
    
    },
    computed: {
        activeRoutes() {
            let activeRoutesArray = [...routerArray];
            // Set array and remove loader and algorithm as these are not indexed
            let unindexedRoutes = ['results'];

            // If user is male, remove the pregancy slide
            if(this.customerGender === 'Male') {
                unindexedRoutes.push('pregnancy');
            }

            unindexedRoutes.forEach(routeName => {
                const arrayIndex = activeRoutesArray.findIndex(object => {
                    return object.name === routeName;    
                });

                activeRoutesArray.splice(arrayIndex, 1);
            });

            return activeRoutesArray;
        },
        activeStep() {
            let indexNo = 0;
            // On initial load of app check route index and return as active step
            this.activeRoutes.forEach((route, index) => {
                if(this.$route.name == route.name) {
                    indexNo = index
                }
            });

            return indexNo;
        },
        totalSteps() {
            return this.activeRoutes.length;
        }
    },
    render: h => h(App)
})
