<template>
    <div>
        <div key="0" v-if="step == 0">
            <div class="panel panel--dark">
                <div class="site-wrap">
                    <div class="recipe-questions-container">
                        <div class="recipe-questions">
                            <div class="recipe-transition" :class="{'recipe-transition--active': panelTransition}">
                                <div class="recipe-questions__title">
                                    <h1 v-if="$root.customerName">
                                        <span>
                                            {{ $root.datasetData.skin.title }}, <span data-cs-mask>{{ $root.customerName }}?</span>
                                        </span>
                                    </h1>
                                    <h1 v-else>
                                        <span>
                                            {{ $root.datasetData.skin.title }}?
                                        </span>
                                    </h1>
                                    <p v-if="$root.datasetData.skin.subtitle">
                                        {{ $root.datasetData.skin.subtitle }}
                                        <template v-if="hasDescriptions">
                                            <button
                                                class="button-reset margin-left-sml"
                                                type="button"
                                                @click="toggleOverlay"
                                            >
                                                <span class="visuallyhidden">
                                                    {{ $root.textHelp }}
                                                </span>
                                                <IconInfo/>
                                            </button>
                                        </template>
                                    </p>
                                </div>
                                <options
                                    :options="this.$root.datasetData.skin.answers"
                                    :columns="parseInt(this.$root.datasetData.skin.columns)"
                                    :selected-options="selectedOptions"
                                    :maxSelection="1"
                                    :preventFirstDeselect="true"
                                    :icon-layout="($root.datasetData.skin.iconLayout == 'true')"
                                ></options>
                            </div>
                            <div class="recipe-questions__footer">
                                <button @click="prev()" class="button button-reset recipe-back button--recipe-back">
                                    <span>
                                        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.281165 9.95757L8.2812 18.2658C8.65425 18.6438 9.2597 18.6878 9.69526 18.304C10.0839 17.9623 10.0937 17.2941 9.73432 16.9219L2.45725 9.2856L3.31229 8.30815L9.73432 1.64931C10.0937 1.27704 10.0683 0.626015 9.69526 0.267151C9.30073 -0.110848 8.64835 -0.0783902 8.2812 0.30533L0.281165 8.61361C0.0467891 8.83888 0.00381934 9.05651 -8.59726e-05 9.2856C-8.59923e-05 9.51085 0.140539 9.81247 0.281165 9.95757Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span class="button__text">
                                        {{ $root.textBack }}
                                    </span>
                                </button>
                                <button @click="next()" :disabled="!step1Complete" class="button button--solid-light button--border button--recipe">
                                    <span class="button__text">
                                        {{ $root.textNext }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div key="1" v-else-if="step == 1">
            <div class="panel panel--dark">
                <div class="site-wrap">
                    <div class="recipe-questions-container">
                        <div class="recipe-questions">
                            <div class="recipe-questions__title">
                                <h1>
                                {{ $root.textGoalTitle }}
                                </h1>
                                <p>
                                    {{ $root.textGoalYourConcern }}: {{ mainGoal.title }}
                                </p>
                            </div>
                            <SSOptions
                                :options="mainGoal.ss_options"
                                :ssOption="ssOption"
                                :columns="2"
                                @updateSSOption="updateSSOption"
                            ></SSOptions>
                            <div class="recipe-questions__footer">
                                <button @click="prev()" class="button button-reset recipe-back button--recipe-back">
                                    <span>
                                        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.281165 9.95757L8.2812 18.2658C8.65425 18.6438 9.2597 18.6878 9.69526 18.304C10.0839 17.9623 10.0937 17.2941 9.73432 16.9219L2.45725 9.2856L3.31229 8.30815L9.73432 1.64931C10.0937 1.27704 10.0683 0.626015 9.69526 0.267151C9.30073 -0.110848 8.64835 -0.0783902 8.2812 0.30533L0.281165 8.61361C0.0467891 8.83888 0.00381934 9.05651 -8.59726e-05 9.2856C-8.59923e-05 9.51085 0.140539 9.81247 0.281165 9.95757Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span class="button__text">
                                        {{ $root.textBack }}
                                    </span>
                                </button>
                        
                                <button @click="next()" :disabled="!ssOption" class="button button--solid-light button--border button--recipe">
                                    <span class="button__text">
                                        {{ $root.textNext }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div key="2" v-if="step == 2">
            <div class="panel panel--dark">
                <div class="site-wrap">
                    <div class="recipe-questions-container">
                        <div class="recipe-questions">
                            <div class="recipe-transition" :class="{'recipe-transition--active': panelTransition}">
                                <div class="recipe-questions__title">
                                    <h1 v-if="$root.customerName">
                                        <span>
                                            {{ $root.textGoalOtherTitle }}, {{ $root.customerName }}?
                                        </span>
                                    </h1>
                                    <h1 v-else>
                                        <span>
                                            {{ $root.textGoalOtherTitle}}?
                                        </span>
                                    </h1>
                                    <p>
                                        {{ $root.textGoalSelectMore }}
                                        <template v-if="hasDescriptions">
                                            <button
                                                class="button-reset margin-left-sml"
                                                type="button"
                                                @click="toggleOverlay"
                                            >
                                                <span class="visuallyhidden">
                                                    {{ $root.textHelp }}
                                                </span>
                                                <IconInfo/>
                                            </button>
                                        </template>
                                    </p>
                                </div>
                                <options
                                    :options="this.$root.datasetData.skin.answers"
                                    :columns="parseInt(this.$root.datasetData.skin.columns)"
                                    :selected-options="selectedOptions"
                                    :maxSelection="3"
                                    :preventFirstDeselect="true"
                                    :disabled-options="disabledOptions"
                                    :remove-option="'happy_with_skin'"
                                    :icon-layout="($root.datasetData.skin.iconLayout == 'true')"
                                ></options>
                            </div>
                            <div class="recipe-questions__footer">
                                <button @click="prev()" class="button button-reset recipe-back button--recipe-back">
                                    <span>
                                        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.281165 9.95757L8.2812 18.2658C8.65425 18.6438 9.2597 18.6878 9.69526 18.304C10.0839 17.9623 10.0937 17.2941 9.73432 16.9219L2.45725 9.2856L3.31229 8.30815L9.73432 1.64931C10.0937 1.27704 10.0683 0.626015 9.69526 0.267151C9.30073 -0.110848 8.64835 -0.0783902 8.2812 0.30533L0.281165 8.61361C0.0467891 8.83888 0.00381934 9.05651 -8.59726e-05 9.2856C-8.59923e-05 9.51085 0.140539 9.81247 0.281165 9.95757Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span class="button__text">
                                        {{ $root.textBack }}
                                    </span>
                                </button>
                                <button @click="next()" :disabled="selectedOptions.length == 0" class="button button--solid-light button--border button--recipe">
                                    <span class="button__text">
                                        {{ step2Started ? $root.textNext : $root.textSkip }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <OverlayDescriptions
            :isActive="overlayActive"
            :answerData="this.$root.datasetData.skin.answers"
            @closeOverlay="toggleOverlay"
        />
    </div>
</template>


<script>
    import Options from './Options.vue';
    import SSOptions from './SSOptions.vue';
    import IconInfo from '../IconInfo.vue';
    import OverlayDescriptions from '../OverlayDescriptions.vue';

    export default {
        components: {
            Options,
            SSOptions,
            IconInfo,
            OverlayDescriptions
        },
        data: () => {
            return {
                step: 0,
                selectedOptions: [],
                disabledOptions: '',
                currency: '£',
                ssOption: null,
                overlayActive: false,
                panelTransition: false
            }
        },
        computed: {
            step1Complete() {
                return this.selectedOptions.length > 0;
            },
            step2Complete() {
                return this.selectedOptions.length > 2;
            },
            step2Started() {
                return this.selectedOptions.length > 1;
            },
            ssQuestionsActive() {
                const res = (this.$root.ssQuestions === 'true')
                return res;
            },
            mainGoal () {
                if(this.selectedOptions.length) {
                    const filteredOption = this.$root.datasetData.skin.answers.filter(option => option.name === this.selectedOptions[0]);
                    if(filteredOption[0].ssactive) {
                        const ss_options = [
                            {question: filteredOption[0].ssstatementone, value: filteredOption[0].ssstatementonevalue, image: filteredOption[0].ssstatementoneimage },
                            {question: filteredOption[0].ssstatementtwo, value: filteredOption[0].ssstatementtwovalue, image: filteredOption[0].ssstatementtwoimage }
                        ]
                        filteredOption[0].ss_options = ss_options
                    }
                    
                    return filteredOption[0];
                } else {
                    return false;
                }
            },
            region () {
                const subDomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
                return subDomain === 'www' ? 'us' : subDomain;
            },
            retrieveCustomerName() {
                if (this.$root.customerName) {
                    return this.$root.customerName;
                } else {
                    return null;
                }
            },
            hasDescriptions() {
                let exists = this.$root.datasetData.skin.answers.filter(function (o) {
                    return Object.prototype.hasOwnProperty.call(o, "description");
                }).length > 0;

                return exists;
            }
        },
        mounted() {
            this.panelTransition = true;
            this.currency = window.localStorage.getItem('currency');
            /* eslint-disable */
            dataLayer.push({
                'event': 'forcePageView',
                'url': '/recipe/skin-goals-1'
            })
            /* eslint-enable */
        },
        methods: {
            checkSSActive() {
                return this.mainGoal.ssactive && this.step == 0 && this.ssQuestionsActive;
            },
            toggleOverlay () {
                this.overlayActive = !this.overlayActive;
            },
            next() {
                if (this.selectedOptions[0] == 'happy_with_skin') {
                    this.$router.push({
                        name: 'eye',
                        params: {
                            skin: this.selectedOptions.join(',')
                        }
                    });
                } else if (this.step == 2) {
                    let ssSelected;
                    if(this.ssOption) {
                        ssSelected = ',ss_' + this.ssOption
                    } else {
                        ssSelected = '';
                    }
                    
                    this.$router.push({
                        name: 'eye',
                        params: {
                            skin: this.selectedOptions.join(',') + ssSelected
                        }
                    });
                } else if(this.checkSSActive()) {
                    this.step = 1;
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                    /* eslint-disable */
                    dataLayer.push({
                        'event': 'forcePageView',
                        'url': '/recipe/skin-goals-ss'
                    })

                } else {
                    this.step = 2;    
                    this.disabledOptions = this.selectedOptions[0]
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                    /* eslint-disable */
                    dataLayer.push({
                        'event': 'forcePageView',
                        'url': '/recipe/skin-goals-2'
                    })
                    /* eslint-enable */
                }
            },
            prev() {
                if (this.step === 0) {
                    this.$router.push({
                        name: 'knowledge'
                    })
                } else if(this.step === 1) {
                    this.step--;
                } else if(this.step === 2) {
                    this.step = 0;
                }
            },
            updateSSOption(value) {
                this.ssOption = value;
            }
        }
    }

</script>
