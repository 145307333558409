/**
 * BEFORE YOU ADD ANOTHER OPTION!
 *
 * All options here MUST be present in the appropriate field in Mailchimp, otherwise
 * customers will receive an error when trying to subscribe to the mailing list.
 */
export default [
    {
        name: "circles",
        title: "Dark Circles",
        de_title: "Dunkle Ringe",
        resultsTitle: "Dark Circles",
        description: "<p>A common concern, dark circles can be caused by factors including a lack of sleep, diet and travelling. However, skincare products cannot help with genetic dark circles.</p>",
        keyProduct: null
    },
    {
        name: "eye_fine_lines",
        title: "Fine Lines and Wrinkles",
        de_title: "Feine Linien und Falten",
        resultsTitle: "Fine Lines and Wrinkles",
        description: "<p>Often the first signs of ageing, fine lines and wrinkles will appear in the creases of the eye area.</p>",
        keyProduct: null
    },
    {
        name: "puffiness",
        title: "Under Eye Puffiness",
        de_title: "Schwellungen unter den Augen",
        resultsTitle: "Under Eye Puffiness",
        description: "<p>Under-eye puffiness is often caused by water retention underneath the eye, as well as factors such as lack of sleep. However, skincare products cannot help with puffiness caused by fat deposits.</p>",
        keyProduct: null
    },
    {
        name: "all_of_the_above",
        title: "All three",
        de_title: "Alle drei",
        resultsTitle: "All Eye Goals",
        description: "",
        keyProduct: null
    },
    {
        name: "no_concerns",
        title: "No Concerns",
        de_title: "Keine Bedenken",
        resultsTitle: "No Eye Concerns",
        description: "",
        keyProduct: null
    }
];
