export default [
    {
        name: "breakouts",
        title: "Acne & breakouts",
        de_title: "Akne & Unreinheiten",
        resultsTitle: "Acne & breakouts",
        landingTitle: "Breakouts",
        description: "Inflamed or infected pores that can become angry pimples. These can appear anywhere on the face, but more commonly around the forehead, nose and chin.",
        ssActive: true,
        showOnRegions: ['us'],
        ssQuestions: [
            {
                question: "I have stubborn and persistent acne in the form of whiteheads and inflamed spots",
                value: "yes"
            },
            {
                question: "I have oily, blemish or breakouts prone skin",
                value: "no"
            }
        ]
    },
    {
        name: "blackheads",
        title: "Blackheads",
        de_title: "Mitesser",
        resultsTitle: "Blackheads",
        landingTitle: "Blackheads",
        description: "<p>Often seen around the nose, forehead and chin, making pores appear larger and more pronounced.</p>"
    },
    {
        name: "texture",
        title: "Uneven skin texture",
        de_title: "Unebenes Hautbild",
        resultsTitle: "Uneven skin texture",
        landingTitle: "Texture",
        description: "<p>To target general skin uneveness, and smooth under the skin bumps and texture.</p>",
        ssActive: false,
        ssQuestions: [
            {
                question: "I have more noticeable texture or deeper marks which give the appearance of more uneven skin",
                value: "yes"
            },
            {
                question: "I have areas of slight texture which gives the appearance of slight uneven skin",
                value: "no"
            }
        ]
    },
    {
        name: "acne_scars",
        title: "Acne scars",
        de_title: "Aknenarben",
        resultsTitle: "Acne scars",
        landingTitle: "Acne",
        description: "Uneven skin tone in the areas where previous breakouts were present.",
        ssActive: false,
        ssQuestions: [
            {
                question: "I have marks in the form of post-acne scarring and dark discoloration and I am concerned about uneven skin tone and texture",
                value: "yes"
            },
            {
                question: "I am concerned about slight discoloration and red post-blemish marks",
                value: "no"
            }
        ]
    },
    {
        name: "brighten",
        title: "Dull skin",
        de_title: "Stumpfe Haut",
        resultsTitle: "Dull skin",
        landingTitle: "Dull skin",
        description: "Skin is lacking radiance all over the face."
    },
    {
        name: "hyperpigmentation",
        title: "Hyperpigmentation",
        de_title: "Hyperpigmentierung",
        resultsTitle: "Hyperpigmentation",
        landingTitle: "Hyperpigmentation",
        description: "Often seen as dark spots, marks or uneven skin tone."
    },
    {
        name: "fine_lines",
        title: "Fine lines & wrinkles",
        de_title: "Feine Linien und Falten",
        resultsTitle: "Fine lines & wrinkles",
        landingTitle: "Fine lines & wrinkles",
        description: "Skin has a lack of elasticity and lines can appear around the eyes and forehead.",
        ssActive: true,
        ssQuestions: [
            {
                question: "I have deep wrinkles which give the appearance of uneven texture and tone",
                value: "yes"
            },
            {
                question: "I have fine lines and wrinkles",
                value: "no"
            }
        ]
    },
    {
        name: "hydration",
        title: "Dehydrated skin",
        de_title: "Dehydrierte Haut",
        resultsTitle: "Dehydrated skin",
        landingTitle: "Dehydrated skin",
        description: "Skin can appear dull with fine lines becoming more prominent.",
        ssActive: true,
        ssQuestions: [
            {
                question: "I have very dry, rough and flakey skin",
                value: "yes"
            },
            {
                question: "I have dry or dehydrated skin and just need a moisture boost",
                value: "no"
            }
        ]
    },
    {
        name: "redness",
        title: "Redness",
        de_title: "Rötungen",
        resultsTitle: "Redness",
        landingTitle: "Redness",
        description: "Skin has a red undertone and can look irritated, often seen most prominently in the cheeks and more common in sensitive and dry skin types.",
        ssActive: true,
        ssQuestions: [
            {
                question: "I have persistent bright redness, and I am looking to sooth and calm my skin",
                value: "yes"
            },
            {
                question: "I have mild or occasional redness, and I am looking to sooth and calm my skin",
                value: "no"
            }
        ]
    },
    {
        name: "oiliness",
        title: "Excess oil",
        de_title: "Überschüssiges Öl",
        resultsTitle: "Excess oil",
        landingTitle: "Excess oil",
        description: "Skin can appear greasy or shiny, particularly prominent on the t-zone, forehead, nose and chin.",
        ssActive: true,
        ssQuestions: [
            {
                question: "I have excess oil, all over the face causing oil related concerns such as enlarged, visible pores and textured skin",
                value: "yes"
            },
            {
                question: "I have combination skin or t-zone oiliness",
                value: "no"
            }
        ]
    },
    {
        name: "dryness",
        title: "Dry skin",
        de_title: "Trockene Haut",
        resultsTitle: "Dry skin",
        landingTitle: "Dry skin",
        description: "Skin can feel tight and can be flakey or itchy.",
        ssActive: true,
        ssQuestions: [
            {
                question: "I have very dry, rough and flaky skin",
                value: "yes"
            },
            {
                question: "I have dry or dehydrated skin and just need a moisture boost",
                value: "no"
            }
        ]
    },
    {
        name: "happy_with_skin",
        title: "I am happy with my skin at the moment",
        de_title: "Ich bin im Moment mit meiner Haut zufrieden",
        resultsTitle: "I am happy with my skin at the moment",
        landingTitle: "I am happy with my skin at the moment"
    }
];
