<template>
    <div>
        <ul class="recipe-options recipe-options--ss" :class="optionClasses">
            <li v-for="(option, index) in options" :key="index" class="recipe-options__option">
                <button
                    type="button"
                    @click="toggleOption(option)"
                    class="button-reset recipe-option__button"
                    :class="[
                        { 'selected': isSelected(option) },
                        { 'flex flex-col': option.image != 'null' }
                    ]"
                >
                    <div
                        v-if="option.image != 'null'"
                        class="relative w-full h-[7.125rem] mb-[.625rem] overflow-hidden rounded-[.25rem]"
                    >
                        <img
                            width="560"
                            height="250"
                            class="absolute w-full h-full object-cover"
                            :src="option.image"
                        >
                    </div>
                    <span>
                        {{ option.question }}
                    </span>
                </button>
            </li>
        </ul>
    </div>
</template>

<script>

    export default {
        data: () => {
            return {
                optionData: [],
                overlayActive: false
            }
        },
        name: 'ssOptions',
        props: {
            options: {
                type: Array,
                default() {
                    return []
                } 
            },
            ssOption: {
                type: String,
                default: 'no'
            },
            maxSelection: {
                type: Number,
                default: 1
            },
            columns: {
                type: Number,
                default: 3
            }
        },
        computed: {
            optionClasses() {
                let classes = '';
                if(this.columns) {
                    classes += `recipe-options--${this.columns} `;
                }
                if(this.columns == 1) {
                    classes += `recipe-options--small `;
                }
                if(this.columns == 2) {
                    classes += `recipe-options--medium `;
                }

                return classes;
            }
        },
        methods: {
            isSelected(option) {
                return this.ssOption == option.value;
            },
            toggleOption(option) {
                if (this.isSelected(option)) {
                    this.$emit('updateSSOption', null);
                } else {
                    this.$emit('updateSSOption', option.value);
                }
            }
        }
    }

</script>
