<template>
    <div class="results" :class="{ 'is-loading': isLoading }">
        <div v-if="isLoading" class="results__spinner-wrap">
            <div class="results__spinner">&nbsp;</div>
        </div>
        <div class="site-wrap">
            <div class="results__hero">
                <h2 class="results__hero-title title-medium text-no-transform">
                    <template v-if="$root.customerName">
                        <span data-cs-mask>{{ $root.customerName }}'s</span>
                    </template>
                    {{ $root.textPersonalized }}
                    <span class="text-weight-normal">
                        {{ $root.textSkinRoutine }}
                    </span>
                </h2>
                <p v-if="recipeAnswers">
                    {{ $root.textFor }} <strong class="text-gradient text-gradient--on-white">{{ mainSkinGoal }}</strong>
                </p>
                <div class="results__hero-copy">
                    <p v-html="this.$root.heroTextarea"></p>
                </div>
                <button class="button-reset results-goals__button" @click="restartQuiz()">
                    Restart quiz
                </button>
                <!-- @TODO bug when removing this, look into and remove hidden class -->
                <span class="title-small text-gradient text-gradient--on-white hidden">
                    {{ Number(inStock.routine.total).toFixed(2) }}
                </span>
            </div>
        </div>
        <div ref="results-wrap" class="results__wrap">
            <div class="results__header">
                <div class="site-wrap">
                    <h2 class="subheading-medium text-uppercase">
                        {{ $root.textChooseRountine }}
                    </h2>
                    <ol class="results__tabs" :class="['is-' + recipe_type, {'results__tabs--essential-disabled': $root.essentialDisabled === 'true'}]">
                        <li v-if="$root.essentialDisabled !== 'true'" class="results__tab" @click="recipe_type = 'essential'; anchorTo('results-wrap')">
                            {{ $root.textEssential }}
                        </li>
                        <li class="results__tab" :class="{'results__tab--essential-disabled': $root.essentialDisabled === 'true'}" @click="recipe_type = 'core'; anchorTo('results-wrap')">
                            {{ $root.textCore }}
                        </li>
                        <li class="results__tab" :class="{'results__tab--essential-disabled': $root.essentialDisabled === 'true'}" @click="recipe_type = 'advanced'; anchorTo('results-wrap')">
                            {{ $root.textAdvanced }}
                        </li>
                    </ol>
                </div>
            </div>
            <div class="results__inner">
                <div class="site-wrap">
                    <div class="results__overview">
                        <h3 class="title-small text-transform-none">
                            {{ $root.textYour }} {{ recipeText(recipe_type) }}  {{ $root.textRoutine }}
                        </h3>
                        <div v-if="results" id="dy-product-results" class="results__overview-products">
                            <template v-for="(result, resultIndex) in results[recipe_type]">
                                <template v-if="result.products.length > 0">
                                    <template v-for="(product, productIndex) in result.products">
                                        <div v-if="product && product.productData.imageUrl" class="results__overview-products-step"  :key="`result-${resultIndex}-product-${productIndex}`">
                                            <div v-if="product.productData.product_type == 'Super Solutions'" class="results__overview-ss-logo">
                                                <SuperSolutionsLogo />
                                            </div>
                                            <img :key="resultIndex + '-' + productIndex"
                                                :src="product.productData.imageUrl"
                                                class="media__item"
                                                :srcset="`${product.productData.imageUrl} 1x,
                                                    ${product.productData.imageUrlRetina} 2x`"
                                                width="116px"
                                                height="116px"
                                            >
                                            <div :key="'result-'+resultIndex" class="results__overview-products-title" :class="{'results__overview-products-title--small' : result.step.title == 'Eye treatment'}">
                                                {{ result.step.title }}
                                            </div>
                                            <div v-if="product.productData.name" class="results__overview-products-name hidden">
                                                {{ product.productData.name }}
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </div>
                        <div class="margin-top-med margin-bot-med">
                            <p v-if="recipe_type === 'essential'" class="body-medium margin-top-med">
                                {{ this.$root.essentialCartPrompt }}
                            </p>
                            <p v-if="recipe_type === 'core'" class="body-medium margin-top-med">
                                {{ this.$root.coreCartPrompt }}
                            </p>
                            <p v-if="recipe_type === 'advanced'" class="body-medium margin-top-med">
                                {{ this.$root.advancedCartPrompt }}
                            </p>
                        </div>
                    </div>
                    <ol ref="results" class="results__list">
                        <template v-for="(result, resultTypeIndex) in results[this.recipe_type]">
                            <li :key="'result_type-'+resultTypeIndex" v-if="result.products.length > 0" class="results__list-item" :data-step-text="$root.step">
                                <h2 class="subheading-large results__list-item-title">
                                    {{ $root[result.step.name] }}
                                </h2>
                                <template v-for="(product, index) in result.products">
                                    <ProductItem
                                        v-if="product"
                                        :key="index"
                                        :productData="product.productData"
                                        @toggleOverlay="toggleProductOverlay(product.productData)"
                                    />
                                </template>
                            </li>
                        </template>
                    </ol>
                    <div class="results__signoff">
                        <div class="results__signoff-block results__signoff-block--grey" :style="'background-image: url(' + this.$root.signoffImage + ')'">
                            <h3 class="results__signoff-title">
                                {{ $root.textShare }}
                            </h3>
                            <div class="results__signoff-buttons">
                                <button class="results__share-link results__share-link--download" @click.prevent="toggleRecipeDownload()" type="button">
                                    {{ $root.textDownload }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <OverlayProduct
            :isActive="productToggle"
            :productData="overlayProductData"
            @closeOverlayProduct="closeProductOverlay"
        />
        <DownloadRecipe
            :isActive="downloadOpen"
            @toggleOverlay="toggleRecipeDownload"
        />
    </div>
</template>

<script>
    import skinGoals from '@/config/recipe-builder/skin-goals';
    import eyeGoals from '@/config/recipe-builder/eye-goals';
    import skinTypes from '@/config/recipe-builder/skin-types';
    import sensitivities from '@/config/recipe-builder/sensitivities';
    import pregnancy from '@/config/recipe-builder/pregnancy';
    import noScroll from 'no-scroll';
    import ProductItem from '@/components/ProductItem';
    import OverlayProduct from '@/components/OverlayProduct';
    import SuperSolutionsLogo from '@/components/SuperSolutionsLogo';
    import GetRecipe from "@/js/get-recipe";
    import DownloadRecipe from "@/components/DownloadRecipe";
    import RecipeDownloadCanvas from "@/js/recipe-download-canvas.js";

    export default {
        data: () => {
            return {
                isLoading: true,
                recipe_type: null,
                openSelectedOptions: false,
                results: {
                    essential: [],
                    core: [],
                    advanced: []
                },
                downloadOpen: false,
                productToggle: false,
                overlayProductData: {}
            }
        },

        components: {
            ProductItem,
            OverlayProduct,
            DownloadRecipe,
            SuperSolutionsLogo
        },

        computed: {
            selectedOptions () {
                let selectedOptions = [];
                const getOptions = (param, options, step) => {
                    if (param) {
                        const paramOptions = param.split(',');
                        options.forEach(option => {
                            if (paramOptions.includes(option.name)) {
                                option['optionStep'] = step;
                                selectedOptions.push(option);
                            }
                        });
                    }
                };

                getOptions(this.$route.params.skin, skinGoals, 'Skin Goals');
                getOptions(this.$route.params.eye, eyeGoals, 'Eye Goals');
                getOptions(this.$route.params.type, skinTypes, 'Skin Types');
                getOptions(this.$route.params.sensitivity, sensitivities, 'Sensitive Skin');
                getOptions(this.$route.params.pregnancy, pregnancy, 'Pregnancy');
                return selectedOptions;
            },
            mainSkinGoal () {
                let self = this;
                let mainGoal = null;

                skinGoals.forEach(goal => {
                    if (goal.name === self.recipeAnswers.main_goal) {
                        mainGoal = goal.title;
                    }
                });
                
                return mainGoal;
            },
            recipeAnswers() {
                const goals = this.$route.params.skin.split(',');
                const mainGoal = goals[0]
                goals.splice(0, 1);

                const answers = {
                    "knowledge": this.$route.params.knowledge,
                    "main_goal": mainGoal,
                    "other_goals": goals,
                    "eye_concern": this.$route.params.eye,
                    "skin_type": this.$route.params.type,
                    "sensitivity": this.$route.params.sensitivity,
                    "pregnancy": this.$route.params.pregnancy
                }

                return answers;
                
            },
            inStock () {
                if(this.results) {
                        let routine = {
                        products: [],
                        subTotal: 0,
                        total: 0
                    }

                    this.results[this.recipe_type].forEach(result => {

                        if(result.products.length > 0) {
                            result.products.forEach(product => {
                                let price = product.productData.price;
                                if (this.tieredDiscounts) {
                                    routine.products.push({
                                        quantity: 1,
                                        id: product.productData.varientId,
                                        parentId: product.productData.parent_id,
                                        title: product.productData.name,
                                        price: price,
                                        linePrice: price
                                    });
                                    routine.subTotal += parseFloat(price);
                                    routine.total += parseFloat(price);
                                } else {  
                                    routine.products.push({
                                        quantity: 1,
                                        id: product.productData.varientId,
                                        parentId: product.productData.parent_id,
                                        title: product.productData.name,
                                        price: price,
                                        linePrice: price
                                    });
                                    routine.subTotal += parseFloat(price);
                                }
                            });
                        }
                    });

                    // Essential discount set via Shopify scripts
                    // Discount must be manually calculated as discount will only be applied once added to cart
                    if (this.recipe_type === 'essential') {
                        if(Number(this.$root.essentialDiscount)) {
                            const discount = Number(this.$root.essentialDiscount) / 100;
                            const totalString = routine.subTotal - (routine.subTotal * discount);
                            routine.total = totalString;
                        } else {
                            routine.total = routine.subTotal;
                        }
                    }

                    // remove loading styles once product data is available
                    if (routine.products.length >= 1) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.isLoading = false;
                        noScroll.off();
                    }

                    return {
                        routine: routine
                    }
                } else {
                    return null
                }
            }
        },

        methods: {
            toggleProductOverlay(productData) {
                this.productToggle = !this.productToggle;
                this.overlayProductData = productData;
                noScroll.on();
            },
            closeProductOverlay () {
                this.productToggle = false;
                noScroll.off();
            },
            anchorTo (refName) {
                let element = this.$refs[refName];
                element.scrollIntoView({ behavior: 'smooth' });
            },
            toggleRecipeDownload () {
                // Safari desktop downloads base64 images without extension, browsercheck to remove overlay
                const uA = navigator.userAgent;
                const vendor = navigator.vendor;
                if (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)) {
                    this.createRecipeCanvas();
                } else {
                    this.downloadOpen = !this.downloadOpen;
                    if(this.downloadOpen === true) {
                        this.createRecipeCanvas();
                    }
                }
            },
            createRecipeCanvas() {
                const downloadDiv = document.querySelector('#recipeDownloadImage');
                let downloadDivImage = downloadDiv.querySelector('img');

                if(downloadDivImage) {
                    downloadDivImage.remove();
                }
                const recipeTypeCapitalised = this.recipe_type.charAt(0).toUpperCase() + this.recipe_type.slice(1);
                new RecipeDownloadCanvas(this.results.advanced, downloadDiv, this.$root.customerName, recipeTypeCapitalised, this.mainSkinGoal);

            },
            initResults () {
                this.recipe.initResults()
                this.results = this.recipe.returnResults();
            },
            getResults (update) {
                if (update) {
                    self.updateRecipe();
                }
            },
            updateRecipe() {
                this.recipe = new GetRecipe(
                    this.$route.params.skin.split(','),
                    this.$route.params.eye,
                    this.$route.params.type,
                    this.$route.params.sensitivity,
                    this.$route.params.pregnancy
                );

                this.initResults();
            },
            sendAnalytics () {
                /* eslint-disable */
                // force a GA page view
                dataLayer.push({
                    'event': 'resultsPageView',
                    'url': window.location.pathname + window.location.hash
                })
                /* eslint-enable */


                this.results.advanced.forEach((result) => {
                    result.products.forEach((product) => {
                        /* eslint-disable */
                        dataLayer.push({
                            'event': 'recipeResultProduct',
                            'product': product.productData.name
                        })
                        /* eslint-enable */
                    })
                })
            },
            recipeText (name) {
                if(name) {
                    return this.$root[`text${name.charAt(0).toUpperCase() + name.slice(1)}`];    
                } else {
                    return '';
                }
            },
            mountResults () {
                this.recipe = new GetRecipe(
                    this.$route.params.skin.split(','),
                    this.$route.params.eye,
                    this.$route.params.type,
                    this.$route.params.sensitivity,
                    this.$route.params.pregnancy
                );

                // init results logic
                this.initResults();
            },
            truncateDecimal(number) {
                // Convert the number to a string
                const numberString = number.toString();

                // Split the string at the decimal point
                const parts = numberString.split('.');

                // If there are more than 1 parts, and the third part exists, truncate it
                if (parts.length > 1 && parts[1].length >= 3) {
                    parts[1] = parts[1].slice(0, 2);
                }

                // Join the parts back together and parse it back to a number
                const truncatedNumber = parseFloat(parts.join('.'));

                return truncatedNumber;
            },
            restartQuiz() {
                this.$router.push({
                    name: 'about'
                });
            },
            triggerGaEvents() {
                if(this.$root.triggerAnalytics) {
                    // eslint-disable-next-line no-undef
                    gtag('event', 'Quiz completed', {
                        'event_category': 'Skin quiz',
                        'event_label': 'Skin quiz completed'
                    });

                    // Routine answers
                    Object.keys(this.$root.datasetData).forEach(key => {
                        if(key == 'skin') {
                            const answers = this.recipeAnswers;
                            const mainAnswer = this.$root.datasetData[key].answers.filter(answer => answer.name === answers.main_goal)[0]
                            const filteredOtherGoals = answers.other_goals.filter(goal => !goal.includes('ss_'))

                            let otherValue = ''
                            if(filteredOtherGoals.length) {
                                filteredOtherGoals.forEach((goal, index) => {
                                    const answer = this.$root.datasetData[key].answers.filter(answer => answer.name === goal)[0];
                                    otherValue += answer.title;
                                    
                                    if(index !== answers.other_goals.length - 1) {
                                        otherValue += ', '
                                    }
                                });
                            }
                            
                            // eslint-disable-next-line no-undef
                            gtag('event', 'Answer', {
                                'event_category': 'Main goal',
                                'event_label': this.$root.datasetData[key].title,
                                'value': mainAnswer.title
                            });
                            

                            if(otherValue) {
                                // eslint-disable-next-line no-undef
                                gtag('event', 'Answer', {
                                    'event_category': 'Other goals',
                                    'event_label': 'Your other skin concerns',
                                    'value': otherValue
                                });
                            }

                        } else {
                            const answer = this.$root.datasetData[key].answers.filter(answer => answer.name === this.$route.params[key])[0]
                            // eslint-disable-next-line no-undef
                            gtag('event', 'Answer', {
                                'event_category': this.capitalizeFirstLetter(this.$root.datasetData[key].type),
                                'event_label': this.$root.datasetData[key].title,
                                'value': answer.title
                            });
                        }
                    
                    });

                }
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
        },

        beforeMount () {
              // Set Recipe Type via Query string
              if (this.$route.query.recipe_type) {
                if (this.$root.essentialDisabled === 'true' && this.$route.query.recipe_type === 'essential') {
                    this.recipe_type = 'core';
                } else {
                    this.recipe_type = this.$route.query.recipe_type;
                }
            } else {
                this.recipe_type = !this.$root.essentialDisabled === 'true' ? 'essential' : 'core';
            }
        }, 

        mounted () {
            // init loading styles
            noScroll.on();

            this.mountResults();
            this.triggerGaEvents();
        }
    }
</script>