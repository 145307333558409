<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <ul class="recipe-options" :class="optionClasses">
            <li v-for="(option, index) in optionsArray" :key="index" class="recipe-options__option">
                <button
                    type="button"
                    @click="toggleOption(option)"
                    class="button-reset recipe-option__button"
                    :class="{ 'selected': isSelected(option) }"
                    :disabled="disabledOptions == option.name"
                >
                    <span v-if="option.icon" class="recipe-option__button-icon">
                        <img v-if="!isSelected(option)"  :src="option.icon" :alt="`Icon for ${option.name}`">
                        <img v-else :src="option.iconhover" :alt="`Icon for ${option.name}`">
                    </span>
                    <span>
                        {{ option.title }}
                    </span>
                </button>
            </li>
        </ul>
        <overlay
            :is-active = "overlayActive"
            :overlay-data = "optionData"
            @closeOverlay = "closeOverlay"
        />
    </div>
</template>

<script>    
    import Overlay from '../TheOverlay';
    import noScroll from 'no-scroll'

    export default {
        data: () => {
            return {
                optionData: [],
                overlayActive: false
            }
        },
        components: {
            Overlay
        },
        name: 'TheOptions',
        props: {
            options: {
                type: Array,
                default() {
                    return []
                }
            },
            selectedOptions: {
                type: Array,
                default() {
                    return []
                }
            },
            disabledOptions: {
                type: String,
                default: ''
            },
            removeOption: {
                type: String,
                default: ''
            },
            maxSelection: {
                type: Number,
                default: 1
            },
            columns: {
                type: Number,
                default: 3
            },
            preventFirstDeselect: {
                type: Boolean,
                default: false
            },
            iconLayout: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            optionsArray() {
                const array = [];
                this.options.forEach(option => {
                    if(option.name != this.removeOption) {
                        array.push(option)
                    }
                });

                return array;
            },
            optionClasses() {
                let classes = '';
                if(this.columns) {
                    classes += `recipe-options--${this.columns} `;
                }
                if(this.columns == 1) {
                    classes += `recipe-options--small `;
                }
                if(this.columns == 2) {
                    classes += `recipe-options--medium `;
                }
                if(this.iconLayout) {
                    classes += `recipe-options--icons `;
                }
                if(this.options.length % 2 == 0) {
                    classes += `recipe-options--total-even `;
                } else {
                    classes += `recipe-options--total-odd `;
                }

                return classes;
            },
        },
        methods: {
            isSelected(option) {
                return this.selectedOptions.includes(option.name);
            },
            toggleOption(option) {
                if (this.isSelected(option)) {
                    if(this.preventFirstDeselect && this.selectedOptions.length > 1) {
                        if(!this.selectedOptions.indexOf(option.name) == 0) {
                            // eslint-disable-next-line vue/no-mutating-props
                            this.selectedOptions.splice(this.selectedOptions.indexOf(option.name), 1);
                        }
                    } else {
                        // eslint-disable-next-line vue/no-mutating-props
                        this.selectedOptions.splice(this.selectedOptions.indexOf(option.name), 1);
                    }
                } else if (this.selectedOptions.length < this.maxSelection) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.selectedOptions.push(option.name);
                } else if (this.selectedOptions.length == this.maxSelection) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.selectedOptions.pop();
                    // eslint-disable-next-line vue/no-mutating-props
                    this.selectedOptions.push(option.name);
                }
            },
            openOverlay(option) {
                this.optionData = option
                this.overlayActive = true
                noScroll.on()
            },
            closeOverlay() {
                this.optionData = []
                this.overlayActive = false
                noScroll.off()
            }
        }
    }

</script>
