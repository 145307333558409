<template>
    <div class="product">
        <div class="product__content">
            <div class="product__image" v-if="productData.imageUrl">
                <img :src="productData.imageUrl"
                    class="media__item"
                    :srcset="`${productData.imageUrl} 344w,
                        ${productData.imageUrlRetina} 688w`"
                    alt=""
                    sizes="488px">
            </div>
            <div class="product__info">
                <div v-if="productData.product_type == 'Super Solutions'" class="product__ss-logo" >
                    <SuperSolutionsLogo />
                </div>
                <div class="product__heading">
                    <h3 class="product__title title-small" :class="{ 'product__ss-title': productData.product_type == 'Super Solutions' }" >
                        {{ productData.name }}
                    </h3>
                </div>
                <div class="product__purchase" :class="{ 'product__purchase--no-stock': !productData.available }">
                <div class="product__buttons">
                    <button @click="triggerProductOverlay" target="_blank" class="button button--solid-light button--small button-border--darkest">
                        <span class="button__text">
                            {{ this.$root.textMoreInfo }}
                        </span>
                    </button>
                </div>
            </div>
            </div>
            <div class="product__copy">
                <div v-if="productData.day_time" class="product__daytime-wrap">
                    <span v-if="productData.day_time == 'am'" class="product__daytime product__daytime--am">
                        AM
                    </span>
                    <span v-else-if="productData.day_time == 'pm'" class="product__daytime product__daytime--pm">
                        PM
                    </span>
                    <div v-else class="product__daytime-both">
                        <span class="product__daytime product__daytime--am">
                            AM
                        </span>
                        +
                        <span class="product__daytime product__daytime--pm">
                            PM
                        </span>
                    </div>
                </div>
                <div v-if="productData.info" class="product__description body-small">
                    {{ productData.info }}
                </div>
            </div>
            <div
                v-if="productData.recipeTooltipColour && productData.recipeTooltipText"
                :style="`border-color: ${productData.recipeTooltipColour}`"
                class="relative flex items-start ml-5 border border-solid rounded-md transition-max-height duration-300 large:mx-24"
                :class="[ tooltipClosed ? 'max-h-0 p-0 opacity-0' : 'p-3 pr-8 large:px-6 large:pr-24' ]"
            >
                <div class="absolute -top-[0.7rem] left-5 w-[1.1875rem] h-[1.1875rem] transform -rotate-45 bg-white border-r border-t border-solid" :style="`border-color: ${productData.recipeTooltipColour} ;`"></div>
                <div class="absolute pr-10 smallMax:left-3 small:relative">
                    <img
                        class="max-w-[2.5rem] medium:max-w-[3.75rem]"
                        :src="productData.recipeTooltipImage"
                        :srcset="`${productData.recipeTooltipImage} 1x,
                            ${productData.recipeTooltipImageRetina} 2x`"
                        alt=""
                    >
                    <div class="absolute top-0 right-6 small:right-3">
                        <svg class="smallMax:w-7" width="43" height="24" viewBox="0 0 43 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M42.7167 11.069C42.7167 14.1268 41.4783 16.894 39.4755 18.8969C37.4727 20.8997 34.7054 22.1381 31.6477 22.1381L14.5854 22.1534C12.1393 22.1534 9.87652 21.3584 8.04187 20.0129C4.14325 22.6885 0 23.3 0 23.3C0 23.3 3.51641 19.8753 3.51641 11.069C3.51641 4.95355 8.46996 0 14.5854 0H31.6477C37.7632 0.0152887 42.7167 4.96883 42.7167 11.069Z" fill="#53C072"/>
                            <circle cx="15.5" cy="10.5" r="2.5" fill="white"/>
                            <circle cx="23.5" cy="10.5" r="2.5" fill="white"/>
                            <circle cx="31.5" cy="10.5" r="2.5" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div>
                    <p class="text-[.9375rem] font-extrabold smallMax:mb-3 smallMax:pl-16 smallMax:pr-5">
                        {{ productData.recipeTooltipTitle }}
                    </p>
                    <p class="text-[.9375rem]">
                        {{ productData.recipeTooltipText }}
                    </p>
                </div>
                <button class="absolute w-5 h-5 top-6 right-0 transform -translate-y-1/2 large:top-1/2 large:right-6" @click="tooltipClosed = true">
                    <span class="absolute w-[.125rem] top-0 left-0 h-5 block transform -rotate-45 bg-black"></span>
                    <span class="absolute w-[.125rem] top-0 left-0 h-5 block transform rotate-45 bg-black"></span>
                </button>
            </div>
            <div v-if="productData.warning || productData.warningHtml" class="product__warning product__warning--application product__warning--margin-top">
                <h3 class="subheading-small margin-bot-sml">
                    <span class="product__warning-icon"></span>
                    {{ this.$root.textApplicationAdvice }}
                </h3>
                <div v-if="productData.warningHtml" class="product__warning-description wysiwyg">
                    <div v-html="productData.warningHtml"></div>
                </div>
                <div v-else class="product__warning-description wysiwyg">
                    {{ productData.warning }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SuperSolutionsLogo from '../components/SuperSolutionsLogo';

    export default {
        components: {
            SuperSolutionsLogo
        },
        props: {
            productData: {
                type: Object,
                required: true
            }
        },
        data: () => {
            return {
                overlayToggle: false,
                freeshipVal: null,
                freeProduct: window.freeProduct,
                tooltipClosed: false
            }
        },
        methods: {
            removeFromBag (obj) {
                this.$emit('removeFromBag', obj);
            },
            toggleStockOverlay () {
                this.overlayToggle = !this.overlayToggle;
            },
            triggerProductOverlay() {
                // Insert product id as parameter in emit
                this.$emit('toggleOverlay', this.productData);
            }

        }
    }
</script>
