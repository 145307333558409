<template>
    <div class="overlay overlay--full" :class="{ 'is-active': isActive }">
        <div class="overlay__inner overlay__inner--tall overlay__inner--full overlay__inner--flex-start">
            <button
                @click="$emit('toggleOverlay')"
                type="button"
                class="button-reset overlay__close-button">
                <span class="visuallyhidden">
                    Close
                </span>
            </button>
            <div class="overlay__content overlay__content--profile">
                <h2 class="heading heading--medium margin-bot-med text-no-transform">
                    {{ $root.textRecipeDownload }}
                </h2>
                <div class="overlay__container">
                    <p>
                        {{ $root.textRecipeDownloadInstructions }}
                    </p>
                    <div id="recipeDownloadImage"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DownloadRecipe',
        props: ['isActive']
    }
</script>
