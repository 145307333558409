export default {
    "knowledge": {
        "type": "knowledge",
        "title": "What's your preferred approach to skincare",
        "subtitle": "",
        "icon-layout": "false",
        "columns": "1",
        "answers": [
            {
                "title": "Keep it simple - just the essentials",
                "name": "essential",
                "description": ""
            },
            {
                "title": "An advanced routine - I want to do the best I can",
                "name": "advanced",
                "description": "I love everything skincare, and want to continue working on my skin goals."
            },
            {
                "title": "I'm not sure? I'm new to skincare.",
                "name": "core",
                "description": "The world of skincare is so confusing, help!"
            }
        ]
    },
    "skin": {
        "type": "skin",
        "title": "What is your main skincare concern",
        "subtitle": "Select one of the options below to continue",
        "icon-layout": "false",
        "columns": "3",
        "answers": [
            {
                "title": "Blemishes & breakouts",
                "name": "breakouts",
                "description": "Inflamed or infected pores that can become angry pimples. These can appear anywhere on the face, but more commonly around the forehead, nose and chin.",
                "ssactive": "true",
                "ssstatementone": "I have stubborn and persistent blemishes and breakouts.",
                "ssstatementonevalue": "yes",
                "ssstatementoneimage": "null",
                "ssstatementtwo": "I don’t have any active blemishes, but I have oily, blemish or breakout prone skin.",
                "ssstatementtwovalue": "no",
                "ssstatementtwoimage": "null"
            },
            {
                "title": "Uneven skin texture",
                "name": "texture",
                "description": "Skin appears uneven with visible bumps under the surface."
            },
            {
                "title": "Blackheads",
                "name": "blackheads",
                "description": "Often seen around the nose, forehead and chin, pores appear larger and more pronounced."
            },
            {
                "title": "Fine lines & wrinkles",
                "name": "fine_lines",
                "description": "Skin has a lack of elasticity and lines can appear around the eyes and forehead.",
                "ssactive": "true",
                "ssstatementone": "I have deep wrinkles which give the appearance of uneven texture and tone",
                "ssstatementonevalue": "yes",
                "ssstatementoneimage": "null",
                "ssstatementtwo": "I have fine lines and wrinkles",
                "ssstatementtwovalue": "no",
                "ssstatementtwoimage": "null"
            },
            {
                "title": "Dull skin",
                "name": "brighten",
                "description": "Skin is lacking radiance all over the face."
            },
            {
                "title": "Blemish scars",
                "name": "acne_scars",
                "description": "Uneven skin tone in the areas where previous breakouts were present.",
                "ssactive": "true",
                "ssstatementone": "I have marks in the form of post-acne scarring and dark discoloration and I am concerned about uneven skin tone and texture",
                "ssstatementonevalue": "yes",
                "ssstatementoneimage": "null",
                "ssstatementtwo": "I am concerned about slight discoloration and red post-blemish marks",
                "ssstatementtwovalue": "no",
                "ssstatementtwoimage": "null"
            },
            {
                "title": "Dehydrated skin",
                "name": "hydration",
                "description": "Skin can appear dull with fine lines becoming more prominent.",
                "ssactive": "true",
                "ssstatementone": "I have skin that often feels tight and fine lines are more exaggerated",
                "ssstatementonevalue": "yes",
                "ssstatementoneimage": "null",
                "ssstatementtwo": "I have dry or dehydrated skin and just need a moisture boost",
                "ssstatementtwovalue": "no",
                "ssstatementtwoimage": "null"
            },
            {
                "title": "Redness",
                "name": "redness",
                "description": "Skin has a red undertone and can look irritated, often seen most prominently in the cheeks and more common in sensitive and dry skin types.",
                "ssactive": "true",
                "ssstatementone": "I have persistent bright redness, and I am looking to soothe and calm my skin",
                "ssstatementonevalue": "yes",
                "ssstatementoneimage": "null",
                "ssstatementtwo": "I have mild or occasional redness, and I am looking to soothe and calm my skin",
                "ssstatementtwovalue": "no",
                "ssstatementtwoimage": "null"
            },
            {
                "title": "Hyperpigmentation",
                "name": "hyperpigmentation",
                "description": "Often seen as dark spots, marks or uneven skin tone."
            },
            {
                "title": "Dry skin",
                "name": "dryness",
                "description": "Skin can feel tight and can be flakey or itchy.",
                "ssactive": "true",
                "ssstatementone": "I have very dry, rough and flaky skin",
                "ssstatementonevalue": "yes",
                "ssstatementoneimage": "null",
                "ssstatementtwo": "I have dry or dehydrated skin and just need a moisture boost",
                "ssstatementtwovalue": "no",
                "ssstatementtwoimage": "null"
            },
            {
                "title": "Excess oil",
                "name": "oiliness",
                "description": "Skin can appear greasy or shiny, particularly prominent on the t-zone, forehead, nose and chin.",
                "ssactive": "true",
                "ssstatementone": "I have excess oil, all over the face causing oil related concerns such as enlarged, visible pores and textured skin",
                "ssstatementonevalue": "yes",
                "ssstatementoneimage": "null",
                "ssstatementtwo": "I have combination skin or t-zone oiliness",
                "ssstatementtwovalue": "no",
                "ssstatementtwoimage": "null"
            }
        ]
    },
    "type": {
        "type": "type",
        "title": "Which of the below best describes your skin type",
        "subtitle": "Please select one to continue",
        "icon-layout": "true",
        "columns": "3",
        "answers": [
            {
                "title": "Combination skin",
                "name": "combination",
                "description": "The T-zone down the centre of the face (forehead, nose, and chin) is generally oily and the cheeks are normal or dry. Blackheads are a regular occurrence and skin can feel tight or dry during winter",
                "icon": require('@/assets/combination.webp'),
                "iconhover": require('@/assets/combination-active.webp')
            },
            {
                "title": "Dry skin",
                "name": "dry",
                "description": "Skin feels tight and dry all-year round and faking and itching is a common occurrence. Lack of sebum and water in the upper layers of the skin leaves the complexion looking tired and dull. Signs of ageing and fine lines may seem more pronounced.",
                "icon": require('@/assets/dry-skin.webp'),
                "iconhover": require('@/assets/dry-active.webp')
            },
            {
                "title": "Normal skin",
                "name": "normal",
                "description": "Overall balanced skin with a smooth texture that would not be classed as too oily or too dry. Mild oiliness, dryness or minor breakouts can appear occasionally.",
                "icon": require('@/assets/normal-skin.webp'),
                "iconhover": require('@/assets/normal-skin-active.webp')
            },
            {
                "title": "Oily skin",
                "name": "oily",
                "description": "Visible, excess oil on the skin, often causing a sheen, congestion, and blocked pore. Pores are enlarged and blackhead and blemishes are a regular occurrence due to excess sebum production.",
                "icon": require('@/assets/oily.webp'),
                "iconhover": require('@/assets/oily-active.webp?v=1676982085&width=500')
            }
        ]
    },
    "sensitivity": {
        "type": "sensitivity",
        "title": "Is your skin sensitive",
        "subtitle": "",
        "icon-layout": "false",
        "columns": "1",
        "answers": [
            {
                "title": "Yes",
                "name": "yes",
                "description": "Is your skin easily aggravated by products or external / lifestyle stressors?"
            },
            {
                "title": "No",
                "name": "no",
                "description": ""
            },
            {
                "title": "A little",
                "name": "little",
                "description": ""
            }
        ]
    },
    "eye": {
        "type": "eye",
        "title": "Do you have any eye area concerns",
        "subtitle": "",
        "icon-layout": "false",
        "columns": "2",
        "answers": [
            {
                "title": "Dark circles",
                "name": "circles",
                "description": "Dark circles can appear beneath the lower lid of the eye and appear as a shadow from puffy eyelids or as a dull coloring and/or sunkenness beneath the eye."
            },
            {
                "title": "Fine lines & wrinkles",
                "name": "eye_fine_lines",
                "description": "Often the first signs of ageing, fine lines and wrinkles will appear in the creases of the eye area where consistent facial movements occur."
            },
            {
                "title": "Under eye puffiness",
                "name": "puffiness",
                "description": "Under-eye puffiness is often caused by water retention underneath the eye which make the area appear swollen."
            },
            {
                "title": "All three",
                "name": "all_of_the_above",
                "description": ""
            },
            {
                "title": "No Concerns",
                "name": "no_concerns",
                "description": ""
            }
        ]
    },
    "pregnancy": {
        "type": "pregnancy",
        "title": "Are you pregnant or breastfeeding",
        "subtitle": "There are some ingredients we advise to avoid during pregnancy and breastfeeding",
        "icon-layout": "false",
        "columns": "2",
        "answers": [
            {
                "title": "Yes",
                "name": "yes",
                "description": "<p>There are some ingredients we advise to avoid during pregnancy and breastfeeding</p>"
            },
            {
                "title": "No",
                "name": "no",
                "description": "<p>There are some ingredients we advise to avoid during pregnancy and breastfeeding</p>"
            }
        ]
    }
}