export default [
    {
        name: "yes",
        title: "Yes",
        de_title: "Ja",
        resultsTitle: "Pregnant or breastfeeding",
        description: "<p>There are some ingredients we advise to avoid during pregnancy and breastfeeding</p>",
        profileTitle: "I’m pregnant or breastfeeding",
        profileIcon: "<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='7' cy='7' r='7' fill='white'/><circle cx='7' cy='7' r='7' fill='white'/><path fill-rule='evenodd' clip-rule='evenodd' d='M6.63552 9.58952L9.62049 5.76224C9.78684 5.54182 9.76378 5.22701 9.53352 5.03186C9.3284 4.85766 8.98391 4.90301 8.81948 5.11598L6.12573 8.52063L5.55838 8.15442L4.69722 7.51608C4.4786 7.35926 4.14558 7.4214 3.98908 7.64039C3.82435 7.87186 3.89023 8.20505 4.11533 8.36501L5.94408 9.69085C6.07765 9.79444 6.19285 9.80014 6.31101 9.78488C6.4269 9.7679 6.57147 9.67281 6.63552 9.58952Z' fill='black'/></svg>"
    },
    {
        name: "no",
        title: "No",
        de_title: "Nein",
        resultsTitle: "Not Pregnant or breastfeeding",
        description: "<p>There are some ingredients we advise to avoid during pregnancy and breastfeeding</p>",
        profileTitle: "I’m not pregnant or breastfeeding",
        profileIcon: "<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='7' cy='7' r='7' fill='white'/><circle cx='7' cy='7' r='7' fill='white'/><path fill-rule='evenodd' clip-rule='evenodd' d='M4.04256 4.18572C4.22414 4.00413 4.51854 4.00413 4.70012 4.18572L10.2421 9.72773C10.4237 9.90931 10.4237 10.2037 10.2421 10.3853C10.0606 10.5669 9.76615 10.5669 9.58457 10.3853L4.04256 4.84328C3.86097 4.6617 3.86097 4.3673 4.04256 4.18572Z' fill='black'/><path fill-rule='evenodd' clip-rule='evenodd' d='M10.2425 4.18562C10.4241 4.3672 10.4241 4.66161 10.2425 4.84319L4.70049 10.3852C4.51891 10.5668 4.22451 10.5668 4.04293 10.3852C3.86135 10.2036 3.86135 9.90922 4.04293 9.72764L9.58494 4.18562C9.76652 4.00404 10.0609 4.00404 10.2425 4.18562Z' fill='black'/></svg>"
    }
];
