export default {
    "textNext": "Next",
    "textBack": "Back",
    "textHelp": "Help",
    "textSkip": "Skip",
    "textAboutTitle": "Let’s get started!",
    "textAboutNameTitle": "What do you like to be called?",
    "textAboutGenderTitle": "What gender do you relate to?",
    "textGenderMale": "Male",
    "textGenderFemale": "Female",
    "textGenderPreferNotToSay": "Prefer not to say",
    "textGoalTitle": "Which best describes your main skincare concern?",
    "textGoalYourConcern": "Your concern",
    "textGoalOtherTitle": "Do you have any other skincare concerns",
    "textGoalSelectMore": "Select up to 2 more or skip",
    "textAccountTitle": "Earn points for completing our skincare quiz, exclusive discounts &amp; so much more when you sign up to our rewards program.",
    "textAccountSignUp": "Sign up (it’s free!)",
    "textAccountAlreadyHaveAccount": "Already have an account? Sign in",
    "textAccountNoThanks": "No thanks, not this time",
    "textAccountNote": "Note: If you have a previous routine saved in your account, this current routine will not be shown in your account unless you sign in.",
    "textEmailHi": "Hi",
    "textEmailText": "Your new routine is one step away. We just wanted to let you know it will automatically be saved in your myINKEY profile.",
    "textEmailCheckbox": "Tick to receive your routine by email",
    "textEmailContinue": "Continue to my routine",
    "textEmailEmailAddress": "Email address",
    "textEmailSkip": "Skip",
    "textAlgorithmTitle": "One Moment",
    "textAlgorithmText": "We&#39;re building your personalised recipe",
    "emailTitle": "Just one more thing",
    "emailText": "Would you like your skincare routine sent to your email?",
    "emailMarketing": "false",
    "emailOptInCopy": "Opt in for game-changing advice, skincare tips and tricks and all our latest product news.",
    "emailMandatory": "false",
    "klaviyoId": "YetyU9",
    "ssQuestions": "true",
    "naApiRequestTransformerBaseUrl": "https://api-transformer.inkey-digital.com",
    "essentialDisabled": "false",
    "textPersonalized": "Personalized",
    "textSkinRoutine": "Skincare Routine",
    "textFor": "for",
    "textEssential": "Essential",
    "textCore": "Core",
    "textAdvanced": "Advanced",
    "textYour": "Your",
    "textRoutine": "routine",
    "textMerch": "Merch",
    "textOosText": "You can still purchase the rest of your routine below.",
    "textAdded": "Added to bag",
    "textAddToBasket": "Add Routine to Basket",
    "textShare": "Save Your Personalized Routine",
    "textDownload": "Download",
    "textEmail": "Email",
    "textUnlockMembers": "Unlock member pricing and more exclusive offers",
    "textGoToProfile": "Go to my profile",
    "textSignUp": "Sign up (it&#39;s free)",
    "textEditAnswers": "Edit your answers",
    "textMoreInfo": "More info",
    "textFullProductDetail": "Full Product Detail",
    "textWhenToApply": "When to apply",
    "textTargets": "Targets",
    "textChangeConcern": "Change your concerns",
    "textClose": "Close",
    "textSkincareApproach": "My skincare approach is...",
    "textMainConcern": "My main skin concern is...",
    "textMainConcernSs": "I would describe my main concern as...",
    "textOtherGoals": "and I also have...",
    "textEyeConcern": "My eye area concern is...",
    "textSkinType": "My skin type is ...",
    "textSensitiveSkin": "Do I have sensitive skin?",
    "textPregnant": "Am I currently pregnant?",
    "textSavingPrompt": "By saving your old recipe will be deleted",
    "textSaveAndUpdate": "Save &amp; Update",
    "textCancel": "Cancel",
    "textPleaseSelect": "Please select",
    "textPleaseSelectAnother": "Please select another concern",
    "textMerchSkip": "Or skip and add routine to bag",
    "textMerchAddRoutine": "Add Routine to Basket",
    "recipeDownload": "Download Recipe",
    "recipeDownloadInstructions": "Download recipe by pressing or right clicking on the image.",
    "heroTitleBold": "Your personalised",
    "heroTitleNormal": "skincare routine",
    "heroTextarea": "",
    "tieredDiscounts": "false",
    "essentialDiscount": "0",
    "coreDiscount": "0",
    "advancedDiscount": "0",
    "essentialSavingsText": "",
    "essentialCartPrompt": "",
    "essentialButtonText": "Add Routine to Bag",
    "coreSavingsText": "",
    "coreCartPrompt": "",
    "coreButtonText": "Add Routine to Bag",
    "advancedSavingsText": "FREE Hyaluronic Acid Serum (worth £9)",
    "advancedCartPrompt": "",
    "advancedButtonText": "Add Routine to Bag",
    "free": "free",
    "signoffImage": require('@/assets/07012022_INKEY_LISA_SUCCINIC_ACID_US_033_3_600x255.webp'),
    "freeProductText": "",
    "freeProductCoreInclude": "false",
    "deliveryBannerEnabled": "true",
    "deliveryBannerFreeStandardQualified": "You qualify for &lt;span&gt;FREE&lt;/span&gt; standard delivery",
    "deliveryBannerFreeExpressQualified": "You qualify for &lt;span&gt;FREE&lt;/span&gt; express delivery",
    "step": "Step",
    "stepClean": "Cleanse",
    "stepHydrate": "Hydrate",
    "stepTreat": "Treat",
    "stepEye": "Eye treatment",
    "stepMoisturize": "Moisturize",
    "stepSpf": "SPF",
    "stepAlsotry": "Also try..."
}