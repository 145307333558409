export default [
    {
        name: "combination",
        title: "Combination Skin",
        de_title: "Mischhaut",
        resultsTitle: "Combination Skin",
        description: "<p>The T-zone down the centre of the face is generally oily and the cheeks are normal or dry.</p><p>Characteristics:</p><ul><li>Produces excess oil on the nose, forehead, or chin</li><li>Skin can feel tight or dry during winter</li><li>Enlarged pores in certain areas, including blackheads</li></ul>",
        profileTitle: "I have Combination Skin",
        profileIcon: "<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='7' cy='7' r='7' fill='white'/><circle cx='7' cy='7' r='7' fill='white'/><path fill-rule='evenodd' clip-rule='evenodd' d='M6.63552 9.58952L9.62049 5.76224C9.78684 5.54182 9.76378 5.22701 9.53352 5.03186C9.3284 4.85766 8.98391 4.90301 8.81948 5.11598L6.12573 8.52063L5.55838 8.15442L4.69722 7.51608C4.4786 7.35926 4.14558 7.4214 3.98908 7.64039C3.82435 7.87186 3.89023 8.20505 4.11533 8.36501L5.94408 9.69085C6.07765 9.79444 6.19285 9.80014 6.31101 9.78488C6.4269 9.7679 6.57147 9.67281 6.63552 9.58952Z' fill='black'/></svg>"
    },
    {
        name: "oily",
        title: "Oily Skin",
        de_title: "Fettige Haut",
        resultsTitle: "Oily Skin",
        description: "<p>Caused by an over production of sebum, which can lead to excess oil, congestion and blocked pores.</p><p>Characteristics:</p><ul><li>Visible, excess oil on the skin, often causing a sheen</li><li>Large visible pores</li><li>Blackheads and blemishes</li></ul>",
        profileTitle: "I have Oily Skin",
        profileIcon: "<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='7' cy='7' r='7' fill='white'/><circle cx='7' cy='7' r='7' fill='white'/><path fill-rule='evenodd' clip-rule='evenodd' d='M6.63552 9.58952L9.62049 5.76224C9.78684 5.54182 9.76378 5.22701 9.53352 5.03186C9.3284 4.85766 8.98391 4.90301 8.81948 5.11598L6.12573 8.52063L5.55838 8.15442L4.69722 7.51608C4.4786 7.35926 4.14558 7.4214 3.98908 7.64039C3.82435 7.87186 3.89023 8.20505 4.11533 8.36501L5.94408 9.69085C6.07765 9.79444 6.19285 9.80014 6.31101 9.78488C6.4269 9.7679 6.57147 9.67281 6.63552 9.58952Z' fill='black'/></svg>"
    },
    {
        name: "dry",
        title: "Dry Skin",
        de_title: "Trockene Haut",
        resultsTitle: "Dry Skin",
        description: "<p>Caused by a lack of sebum in the skin and lack of water in the upper layers of the skin.</p><p>Characteristics:</p><ul><li>Skin feels tight and dry year-round, and may feel worse after bathing or showering</li><li>Visible flaking and itching</li><li>Skin looks tired and in need of a glow</li><li>May have loss of tone and elasticity</li><li>Signs of ageing and fine lines may seem more pronounced</li></ul>",
        profileTitle: "I have Dry Skin",
        profileIcon: "<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='7' cy='7' r='7' fill='white'/><circle cx='7' cy='7' r='7' fill='white'/><path fill-rule='evenodd' clip-rule='evenodd' d='M6.63552 9.58952L9.62049 5.76224C9.78684 5.54182 9.76378 5.22701 9.53352 5.03186C9.3284 4.85766 8.98391 4.90301 8.81948 5.11598L6.12573 8.52063L5.55838 8.15442L4.69722 7.51608C4.4786 7.35926 4.14558 7.4214 3.98908 7.64039C3.82435 7.87186 3.89023 8.20505 4.11533 8.36501L5.94408 9.69085C6.07765 9.79444 6.19285 9.80014 6.31101 9.78488C6.4269 9.7679 6.57147 9.67281 6.63552 9.58952Z' fill='black'/></svg>"
    },
    {
        name: "normal",
        title: "Normal skin",
        de_title: "Normale Haut",
        resultsTitle: "Normal skin",
        description: "<p>We don't believe any skin is more normal than another. However 'normal' skin is used to describe balanced skin that would not be characterized as too oily or too dry. May experience mild oiliness or dryness seasonally.</p><p>Characteristics:</p><ul><li>Fine, small pores</li><li>Smooth skin texture</li><li>Generally few blemishes but may suffer breakouts from time to time</li></ul>",
        profileTitle: "I have Normal skin",
        profileIcon: "<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='7' cy='7' r='7' fill='white'/><circle cx='7' cy='7' r='7' fill='white'/><path fill-rule='evenodd' clip-rule='evenodd' d='M6.63552 9.58952L9.62049 5.76224C9.78684 5.54182 9.76378 5.22701 9.53352 5.03186C9.3284 4.85766 8.98391 4.90301 8.81948 5.11598L6.12573 8.52063L5.55838 8.15442L4.69722 7.51608C4.4786 7.35926 4.14558 7.4214 3.98908 7.64039C3.82435 7.87186 3.89023 8.20505 4.11533 8.36501L5.94408 9.69085C6.07765 9.79444 6.19285 9.80014 6.31101 9.78488C6.4269 9.7679 6.57147 9.67281 6.63552 9.58952Z' fill='black'/></svg>"
    }
];
