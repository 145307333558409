<template>
    <div class="panel panel--dark">
        <div class="site-wrap">
            <div class="recipe-questions-container">
                <div class="recipe-questions">
                    <div class="recipe-transition" :class="{'recipe-transition--active': panelTransition}">
                        <div class="recipe-questions__title">
                            <h1 v-if="$root.datasetData.sensitivity.title && $root.customerName">
                                {{ $root.datasetData.sensitivity.title }}, <span data-cs-mask>{{ $root.customerName }}?</span>
                                <template v-if="hasDescriptions">
                                    <button
                                        class="button-reset margin-left-sml"
                                        type="button"
                                        @click="toggleOverlay"
                                    >
                                        <span class="visuallyhidden">
                                            {{ $root.textHelp }}
                                        </span>
                                        <IconInfo/>
                                    </button>
                                </template>
                            </h1>
                            <h1 v-else> 
                                {{ $root.datasetData.sensitivity.title }}?
                                <template v-if="hasDescriptions">
                                    <button
                                        class="button-reset margin-left-sml"
                                        type="button"
                                        @click="toggleOverlay"
                                    >
                                        <span class="visuallyhidden">
                                            {{ $root.textHelp }}
                                        </span>
                                        <IconInfo/>
                                    </button>
                                </template>
                            </h1>
                            <p v-if="$root.datasetData.sensitivity.subtitle">
                                {{ $root.datasetData.sensitivity.subtitle }}
                            </p>
                        </div>
                        <options
                            :options="$root.datasetData.sensitivity.answers"
                            :columns="parseInt($root.datasetData.sensitivity.columns)"
                            :selected-options="selectedOptions"
                            :disable-entry="step1Complete"
                            :icon-layout="($root.datasetData.sensitivity.iconLayout == 'true')"
                        ></options>
                    </div>
                    <div class="recipe-questions__footer">
                        <button @click="prev()" class="button button-reset recipe-back button--recipe-back">
                            <span>
                                <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.281165 9.95757L8.2812 18.2658C8.65425 18.6438 9.2597 18.6878 9.69526 18.304C10.0839 17.9623 10.0937 17.2941 9.73432 16.9219L2.45725 9.2856L3.31229 8.30815L9.73432 1.64931C10.0937 1.27704 10.0683 0.626015 9.69526 0.267151C9.30073 -0.110848 8.64835 -0.0783902 8.2812 0.30533L0.281165 8.61361C0.0467891 8.83888 0.00381934 9.05651 -8.59726e-05 9.2856C-8.59923e-05 9.51085 0.140539 9.81247 0.281165 9.95757Z" fill="white"/>
                                </svg>
                            </span>
                            <span class="button__text">
                                {{ $root.textBack }}
                            </span>
                        </button>
                        <button @click="next()" :disabled="!step1Complete" class="button button--solid-light button--border button--recipe">
                            <span class="button__text">
                                {{ $root.textNext }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <OverlayDescriptions
            :isActive="overlayActive"
            :answerData="this.$root.datasetData.sensitivity.answers"
            @closeOverlay="toggleOverlay"
        />
    </div>
</template>

<script>
    import Options from './Options.vue';
    import IconInfo from '../IconInfo.vue';
    import OverlayDescriptions from '../OverlayDescriptions.vue';

    export default {
        components: {
            Options,
            IconInfo,
            OverlayDescriptions
        },

        data: () => {
            return {
                step: 0,
                selectedOptions: [],
                overlayActive: false,
                panelTransition: false
            }
        },

        computed: {
            step1Complete() {
                return this.selectedOptions.length > 0;
            },
            hasDescriptions() {
                let exists = this.$root.datasetData.sensitivity.answers.filter(function (o) {
                    return Object.prototype.hasOwnProperty.call(o, "description");
                }).length > 0;

                return exists;
            }
        },

        methods: {
            toggleOverlay () {
                this.overlayActive = !this.overlayActive;
            },
            next() {
                if(this.$root.customerGender !== 'Male') {
                    this.$router.push({
                        name: 'pregnancy',
                        params: {
                            sensitivity: this.selectedOptions.join(',')
                        }
                    });
                } else {
                    this.$root.triggerAnalytics = true;

                    this.$router.push({
                        name: 'results',
                        params: {
                            sensitivity: this.selectedOptions.join(','),
                            pregnancy: 'no'
                        },
                        query: {
                            recipe_type: this.$route.params.knowledge
                        }
                    });
                }
            },
            prev() {
                this.$router.push({
                    name: 'type'
                })
            }
        },

        mounted() {
            this.panelTransition = true;
            /* eslint-disable */
            dataLayer.push({
                'event': 'forcePageView',
                'url': '/recipe/skin-sensitivity'
            })
            /* eslint-enable */
        }
    }

</script>
