<template>
    <div class="panel panel--dark">
        <div class="site-wrap">
            <div class="recipe-questions-container">
                <div class="recipe-questions">
                    <div class="recipe-transition" :class="{'recipe-transition--active': panelTransition}">
                        <div class="recipe-questions__title">
                            <h1 v-if="$root.datasetData.pregnancy.title">
                                <template v-if="$root.customerName">
                                    {{ $root.datasetData.pregnancy.title }}, <span data-cs-mask>{{ $root.customerName }}?</span>
                                </template>
                                <template v-else>
                                    {{ $root.datasetData.pregnancy.title }}?
                                </template>
                            </h1>
                            <p v-if="$root.datasetData.pregnancy.subtitle">
                                {{ $root.datasetData.pregnancy.subtitle }}
                            </p>
                        </div>
                        <options
                            :options="$root.datasetData.pregnancy.answers"
                            :columns="parseInt($root.datasetData.pregnancy.columns)"
                            :selected-options="selectedOptions"
                            :disable-entry="step1Complete"
                            :icon-layout="($root.datasetData.pregnancy.iconLayout == 'true')"
                        ></options>
                    </div>
                    <div class="recipe-questions__footer">
                        <button @click="prev()" class="button button-reset recipe-back button--recipe-back">
                            <span>
                                <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.281165 9.95757L8.2812 18.2658C8.65425 18.6438 9.2597 18.6878 9.69526 18.304C10.0839 17.9623 10.0937 17.2941 9.73432 16.9219L2.45725 9.2856L3.31229 8.30815L9.73432 1.64931C10.0937 1.27704 10.0683 0.626015 9.69526 0.267151C9.30073 -0.110848 8.64835 -0.0783902 8.2812 0.30533L0.281165 8.61361C0.0467891 8.83888 0.00381934 9.05651 -8.59726e-05 9.2856C-8.59923e-05 9.51085 0.140539 9.81247 0.281165 9.95757Z" fill="white"/>
                                </svg>
                            </span>
                            <span class="button__text">
                                {{ $root.textBack }}
                            </span>
                        </button>
                        <button @click="next()" :disabled="!step1Complete" class="button button--solid-light button--border button--recipe">
                            <span class="button__text">
                                {{ $root.textNext }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import options from '@/config/recipe-builder/pregnancy';
    import Options from './Options.vue';

    export default {
        components: {
            Options
        },

        data: () => {
            return {
                options,
                selectedOptions: [],
                panelTransition: false
            }
        },

        computed: {
            step1Complete() {
                return this.selectedOptions.length > 0;
            }
        },

        methods: {
            next() {
                this.$root.triggerAnalytics = true;
                
                this.$router.push({
                    name: 'results',
                    params: {
                        pregnancy: this.selectedOptions.join(',')
                    },
                    query: {
                        recipe_type: this.$route.params.knowledge
                    }
                });
            },
            prev() {
                this.$router.push({
                    name: 'sensitivity'
                })
            }
        },

        mounted() {
            this.panelTransition = true;
            /* eslint-disable */
            dataLayer.push({
                'event': 'forcePageView',
                'url': '/recipe/pregnancy'
            })
            /* eslint-enable */
        }
    }

</script>
