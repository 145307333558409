<template>
    <div :class="{ 'is-active': isActive }" class="overlay">
        <div class="overlay__inner">
            <div class="overlay__content">
                <h2 class="subheading-medium">
                    {{ overlayData[0] }}
                </h2>
                <div class="overlay__text wysiwyg" v-html="overlayData[1]"></div>
                <button
                    type="button"
                    class="button-reset overlay__close"
                    @click="closeOverlay()">
                    Close
                </button>
            </div>
        </div>
        <button
            type="button"
            class="button-reset overlay__close-full"
            @click="closeOverlay()">
            <span class="visuallyhidden">
                Close
            </span>
        </button>
    </div>
</template>

<script>
export default {
    name: "TheOverlay",
    props: {
        isActive: Boolean,
        overlayData: Array
    },
    methods: {
        closeOverlay() {
            this.$emit('closeOverlay')
        }
    }
}
</script>
./Overlay.vue