import steps from "../config/recipe-builder/steps";
import recipeResultsCollection from "@/config/recipe_results_collection";

export default class GetRecipe {
    /**
     * Class constructor
     *
     * @arg {array} - `skinGoals` Array of skin goal strings
     * @arg {string} - `eyeGoal` eye goal string
     * @arg {string} - `skinType` skin type string
     * @arg {string} - 'sensitivity' sensitivity string
     * @arg {string} - 'pregnancy' pregnancy string
    */
    constructor(skinGoals, eyeGoal, skinType, sensitivity, pregnancy) {
        if (
            !skinGoals
            && !eyeGoal
            && !skinType
            && !sensitivity
            && !pregnancy
        ) return;

        this.skinGoals = skinGoals;
        this.eyeGoal = eyeGoal;
        this.skinType = skinType;
        this.sensitivity = sensitivity;
        this.pregnancy = pregnancy;
        this.results = [];
        this.routines = {
            essential: [],
            core: [],
            advanced: []
        };
        this.collection = {
            clean: [
            ],
            hydrate: [
            ],
            serum: [
            ],
            toner: [
            ],
            eye: [
            ],
            moisturizer: [
            ],
            oil: [
            ],
            spf: [
            ],
            alsotry: [
            ]
        }
    }

    /**
     * Init Results
     * Ajax request to skin collection JSON template
     * JSON returned includes all recipe metafields
     * On success pass products to the getResults function
     */
    initResults () {
        let data = recipeResultsCollection.products;
        let includeSuperSolutions = false;

        if (this.skinGoals.includes('ss_yes')) {
            includeSuperSolutions = true;
        }

        data.forEach(product => {
            if (product.step) {
                // Match products upon step and push to collection step array
                let step = product.step.toLowerCase().replace(/\s+/g, '');

                if (product.product_type == 'Super Solutions') {
                    if (includeSuperSolutions) {
                        this.collection[step].push(product);
                    }
                }  else {
                    this.collection[step].push(product);
                }
            }
        });

        this.getResults()
    }

    /**
     * Get Results
     * Run through each recipe step and pass step to getRecommendations function
     */
    getResults () {
        let routines = {
            essential: [],
            core: [],
            advanced: []
        };
        // steps imported on line 30
        steps.forEach(step => {
            for (const key in this.routines) {
                if (Object.prototype.hasOwnProperty.call(step, "group") && step.group) {
                    const parentId = routines[key].findIndex((el) => {
                        return el.step.name === step.group;
                    });
                    routines[key][parentId].products = routines[key][parentId].products.concat(this.getRecommendations(step, key));
                } else {
                    routines[key].push({
                        step,
                        products: this.getRecommendations(step, key)
                    });
                }
            }
          
        });

        this.routines = routines;
    }

    /**
     * Get Recommendations
     * Check each answer param against all products
     * Return the highest scoring product per step
     */
    getRecommendations (step, routine) {
        let products = [];
        let goals = this.skinGoals;
        goals.push(this.eyeGoal);

        const type = this.skinType;
        const sensitivity = this.sensitivity;
        const pregnancy = this.pregnancy;

        if (!Object.prototype.hasOwnProperty.call(this.collection, step.name)) {
            return [];
        }

        // For each product
        this.collection[step.name].forEach(recommendation => {
            // Type must match.
            if (!Object.prototype.hasOwnProperty.call(recommendation.type, type) || recommendation.type[type] === 0) {
                return;
            }
            // Sensitivity must match.
            if (!Object.prototype.hasOwnProperty.call(recommendation.sensitivity, sensitivity) || recommendation.sensitivity[sensitivity] === 0) {
                return;
            }
            // Pregnancy must match.
            if (pregnancy && pregnancy === 'yes') {
                if (recommendation.pregnancy === false) {
                    return;
                }
            }

            let score = 0;

            goals.forEach((goal, i) => {
                if (Object.prototype.hasOwnProperty.call(recommendation.goal, goal) && recommendation.goal[goal] > 0) {
                    score += recommendation.goal[goal];
                    score += recommendation.type[type];
                    score += recommendation.sensitivity[sensitivity];

                    // If a product has a score over 100 then it's a must have product based upon your primary goal
                    const isMainGoal = i === 0;
                    if (isMainGoal) {
                        score += 100;
                    }
                }
            });

            if (score === 0) {
                return;
            }

            products.push({
                productData: recommendation,
                score
            });
            
        });

        // Sort any matching products first by the number of concerns matched. In the event of a tie, prefer
        // the product(s) that match the user's primary concern (first in the list).
        products.sort((a, b) => {
            let aData = a.productData.goal[goals[0]] + a.productData.type[type] + a.productData.sensitivity[sensitivity];
            let bData = b.productData.goal[goals[0]] + b.productData.type[type] + b.productData.sensitivity[sensitivity];

            // if primary goal scores match then use the highest scoring product
            if (aData == bData) {
                aData = a.score;
                bData = b.score;
            }

            return aData  > bData ? -1 : bData > aData ? 1 : 0;
        });

        // Sort any SS products to only include the top SS must have product
        let hasSS = false;

        products.forEach((product, i) => {

            if (product.productData.product_type == 'Super Solutions') {
                // add boolean to product
                product.isSS = false;

                if (!hasSS) {
                    // set SS product to true. This will be the only SS product added to results
                    product.isSS = true;
                    hasSS = true
                }

                if (!product.isSS) {
                    // remove other SS product from array
                    products.splice(i, 1)
                }
            }
        });


        // slice products to max results integer
        // use the advanced recipe to get the maximum number of possible results
        // advanced is the same as full recipe
        products = products.slice(0, step.maxResults[routine]);

        return products;
    }

    /**
     * Return Results
     * Returns the result object
     */
    returnResults () {
        return this.routines;
    }
}
