<template>
    <div class="panel panel--dark">
        <div class="site-wrap">
            <div class="recipe-questions-container">
                <div class="recipe-questions">
                    <div class="recipe-transition" :class="{'recipe-transition--active': panelTransition}">
                        <div class="recipe-questions__title">
                            <h1>
                                {{ $root.textAboutTitle }}
                            </h1>
                        </div>
                        <div class="recipe-questions__grid recipe-questions__grid--small">
                            <div class="recipe-questions__field">
                                <label class="input-label" for="name">{{ $root.textAboutNameTitle }}</label>
                                <div class="recipe-options recipe-options--1">
                                    <div class="recipe-options__option">
                                        <input class="input" type="text" id="name" v-model="nameField" data-cs-mask>
                                    </div>
                                </div>
                            </div>
                            <div class="recipe-questions__field">
                                <label class="input-label">{{ $root.textAboutYouGenderTitle }}</label>
                                <ul class="recipe-options recipe-options--2 recipe-options--last-fw">
                                    <li v-for="(item, index) in genderOptions" :key="index" class="recipe-options__option">
                                        <button type="button" class="button-reset recipe-option__button" :class="genderField == item ? 'selected' : ''" @click="switchGender(item)" :aria-label="item" data-cs-mask>
                                            <span>
                                                {{ item }}
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="recipe-questions__footer">
                        <button @click="prev()" disabled class="button button-reset recipe-back button--recipe-back">
                            <span>
                                <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.281165 9.95757L8.2812 18.2658C8.65425 18.6438 9.2597 18.6878 9.69526 18.304C10.0839 17.9623 10.0937 17.2941 9.73432 16.9219L2.45725 9.2856L3.31229 8.30815L9.73432 1.64931C10.0937 1.27704 10.0683 0.626015 9.69526 0.267151C9.30073 -0.110848 8.64835 -0.0783902 8.2812 0.30533L0.281165 8.61361C0.0467891 8.83888 0.00381934 9.05651 -8.59726e-05 9.2856C-8.59923e-05 9.51085 0.140539 9.81247 0.281165 9.95757Z" fill="white"/>
                                </svg>
                            </span>
                            <span class="button__text">
                                {{ $root.textBack }}
                            </span>
                        </button>
                        <button @click="setGlobalVars(); next();" :disabled="!nameField || !genderField" class="button button--solid-light button--border button--recipe">
                            <span class="button__text">
                                {{ $root.textNext }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => {
            return {
                nameField: '',
                genderField: null,
                genderOptions: [],
                panelTransition: false
            }
        },
        mounted() {
            this.genderOptions.push(this.$root.textGenderMale);
            this.genderOptions.push(this.$root.textGenderFemale);
            this.genderOptions.push(this.$root.textGenderPreferNotToSay);
            this.panelTransition = true;
            /* eslint-disable */
            dataLayer.push({
                'event': 'forcePageView',
                'url': '/recipe/about'
            })
            /* eslint-enable */
        },
        methods: {
            next() {
                this.$router.push({
                    name: 'knowledge'
                });
            },
            prev() {
                if(window.location.href.includes('/de/')) {
                    window.location.href = '/de/pages/recipe'    
                } else {
                    window.location.href = '/pages/recipe'
                }
            },
            switchGender(gender) {
                if(this.genderField == gender) {
                    this.genderField = null;
                } else {
                    this.genderField = gender;
                }
            },
            setGlobalVars() {
                this.$root.customerName = this.nameField;
                this.$root.customerGender = this.genderField;
            }
        },
    }

</script>
