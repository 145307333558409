<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8V14.8148C0 15.4694 0.530625 16 1.18519 16H8C12.4183 16 16 12.4183 16 8Z" fill="white"/>
        <path d="M6.80762 6.13477H8.74658V12H7.40918V7.23047H6.80762V6.13477ZM7.13525 4.32471C7.13525 4.07048 7.22835 3.85205 7.41455 3.66943C7.60075 3.48682 7.81559 3.39551 8.05908 3.39551C8.30257 3.39551 8.51562 3.48682 8.69824 3.66943C8.88086 3.85205 8.97217 4.07048 8.97217 4.32471C8.97217 4.57536 8.88086 4.79199 8.69824 4.97461C8.51562 5.15723 8.30257 5.24854 8.05908 5.24854C7.81201 5.24854 7.59717 5.15723 7.41455 4.97461C7.23193 4.79199 7.14062 4.57357 7.14062 4.31934L7.13525 4.32471Z" fill="black"/>
    </svg>
</template>

<script>
export default {
}
</script>
