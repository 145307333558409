export default class RecipeDownloadCanvas {

    /**
     * Class constructor
     * @arg {Object} - Recipe results
     * @arg {Object} - DOM element used to input canvas
    */

    constructor(results, element, customerName, recipeName, recipeConcern) {
        if(!results) {
            return;
        }
        this.results = results;
        this.element = element;
        this.customerName = customerName;
        this.recipeName = recipeName;
        this.recipeConcern = recipeConcern;
        this.init();
    }

    /**
    * Get images from window
    * Ensure all images have loaded before creating the canvas
    */
    init() {
        // Create Canvas
        this.loadedImages = 0;
        this.graphicImages = [];
        this.canvas = document.createElement('canvas');
        this.canvas.width = 1080;
        this.canvas.height = 1920;

        // Get Images from window
        const graphics = window.recipeDownload;

        // Create images and input into new array
        Object.keys(graphics).forEach(key => {
            let src = graphics[key];
            let img = new Image();
            img.src = src;
            img.crossOrigin = "Anonymous";

            this.graphicImages[key] = img;

            img.onload = () => {
                this.loadedImages++;

                if(this.loadedImages == Object.keys(graphics).length) {
                    this.createCanvas(this.graphicImages);
                }
            }
        });
    }

    /**
    * Set cavas config info
    * Pass created canvas to download method
    */
    createCanvas() {
        // Config
        const ctx = this.canvas.getContext("2d");
        const lineX = 165;
        const lineY = 140;
        // const lineYEnd = 1920;
        const lineWidth = 750;
        const lineEnd = lineX + lineWidth;
        ctx.fillStyle = 'black';
        ctx.strokeStyle = 'black';
        ctx.linewidth = 20;
        let lineHeight = 60;
        let i = 4;
        let j = 0;
        const alsoTryStep = this.results.filter(element => element.step.name === 'alsotry');

        // Add in background image
        ctx.drawImage(this.graphicImages.background, 0, 0);
        
        // Create and add main title
        ctx.font = "600 66px Madras";
        const mainTitle = `${this.customerName ? this.customerName + '\'s ' : '' }Personalized`;
        const mainTitleDims = ctx.measureText(mainTitle);
        const paddingLeftMainTitle = (this.canvas.width - mainTitleDims.width) / 2;
        ctx.fillText(mainTitle, paddingLeftMainTitle, lineY + (1*lineHeight));
        ctx.font = "300 66px Madras";

        // Create and add secondary title
        const mainTitleSecondary = `${this.recipeName} Skincare Routine`;
        const mainTitleSecondaryDims = ctx.measureText(mainTitleSecondary);
        const paddingLeftMainTitleSecondary = (this.canvas.width - mainTitleSecondaryDims.width) / 2;
        ctx.fillText(mainTitleSecondary, paddingLeftMainTitleSecondary, (lineY + (2*lineHeight)) + 8);

        // Create and add tertiary title
        ctx.font = "600 32px Madras";
        const mainTitleTertiary = `for ${this.recipeConcern}`
        const mainTitleTertiaryDims = ctx.measureText(mainTitleTertiary);
        const paddingLeftMainTitleTertiary = (this.canvas.width - mainTitleTertiaryDims.width) / 2;
        let gradient = ctx.createLinearGradient(paddingLeftMainTitleTertiary, (lineY + (3*lineHeight)), mainTitleTertiaryDims.width, 0);
        gradient.addColorStop('0', '#8919DF');
        gradient.addColorStop('0.3', '#7A12DC');
        gradient.addColorStop('0.4', '#7510DB');
        gradient.addColorStop('0.7', '#3A51FF');
        gradient.addColorStop('1.0', '#3A51FF');
        ctx.fillStyle = gradient;
        ctx.fillText(mainTitleTertiary, paddingLeftMainTitleTertiary, lineY + (3*lineHeight));

        // Add some padding below titles
        // i += 0.5;

        // Cycle through steps
        this.results.forEach((element) => {
            if(element.products.length > 0 && element.step.name != 'alsotry') {
                // Increase line height and step count
                i++;
                j++;
                // If is past step 1 add additional padding to top of next title
                if(j > 1) {
                    i += 0.5;
                }

                // Draw rect background for steps
                ctx.fillStyle = "white";
                const boxWidth = 840;
                const paddingLeftCatBox = (this.canvas.width - boxWidth) / 2;
                ctx.fillRect(paddingLeftCatBox, ((lineY + (i*lineHeight) - 60)), boxWidth, (lineHeight * element.products.length) + 130);

                // Create and add Cat Title
                ctx.font = "300 38px Madras";
                let stepText = `STEP ${j}`
                let catText = element.step.title.toUpperCase();
                let stepTextDims = ctx.measureText(stepText);
                let catTitleDims = ctx.measureText(catText);
                let titleDims = stepTextDims.width + catTitleDims.width;
                let paddingLeftCatTitle = (this.canvas.width - titleDims) / 2;
                ctx.fillStyle = "black";
                ctx.fillText(stepText, paddingLeftCatTitle, lineY + (i*lineHeight));
                ctx.font = "600 38px Madras";
                ctx.fillText(catText, (paddingLeftCatTitle + stepTextDims.width) + 10, lineY + (i*lineHeight));

                /// Create Line beneath Steps Title
                ctx.beginPath();
                ctx.moveTo(lineX, lineY + (i*lineHeight + 30));
                ctx.lineTo(lineEnd, lineY + (i*lineHeight + 30));
                let gradientLine = ctx.createLinearGradient(lineX, lineY + (i*lineHeight + 30), lineWidth, 0);
                gradientLine.addColorStop('0', '#8919DF');
                gradientLine.addColorStop('0.3', '#7A12DC');
                gradientLine.addColorStop('0.4', '#7510DB');
                gradientLine.addColorStop('0.7', '#3A51FF');
                gradientLine.addColorStop('1.0', '#3A51FF');
                ctx.strokeStyle = gradientLine;
                ctx.stroke();

                // Cycle through products
                i = i + 0.5;
                ctx.font = "27px Madras";
                element.products.forEach(product => {
                    i++;
                    this.drawProduct(ctx, product, lineX, lineY, lineEnd, lineHeight, i);
                });

                // Add also try step to end of treat step
                if (alsoTryStep && alsoTryStep.length && alsoTryStep[0].products.length && element.step.name === 'treat') {
                    i++;
                    // Get product name information
                    let productName = 'Also try: ' + alsoTryStep[0].products[0].productData.name;
                    // Create bullet point
                    ctx.beginPath();
                    ctx.arc(lineX + 5, lineY + (i*lineHeight) - 9, 7, 0, 2 * Math.PI);
                    ctx.fill();
                    // Add in product name
                    ctx.fillText(`${productName}`, lineX + 25, lineY + (i*lineHeight));

                    if(alsoTryStep[0].products[0].productData.day_time) {
                        if(alsoTryStep[0].products[0].productData.day_time.includes('am|pm')) {
                            ctx.drawImage(this.graphicImages.sunIcon, lineEnd - 60, lineY + (i*lineHeight) - 20);
                            ctx.drawImage(this.graphicImages.moonIcon, lineEnd - 20, lineY + (i*lineHeight) - 20);
                        }
                        else if (alsoTryStep[0].products[0].productData.day_time.includes('am')) {
                            ctx.drawImage(this.graphicImages.sunIcon, lineEnd - 20, lineY + (i*lineHeight) - 20);
                        } else if (alsoTryStep[0].products[0].productData.day_time.includes('pm')) {
                            ctx.drawImage(this.graphicImages.moonIcon, lineEnd - 20, lineY + (i*lineHeight) - 20);
                        }
                    }
                }

                i += 0.5;
            }
        });

        i += 0.5;

        // Add key text
        const applicationWarning = "Application Warning - Avoid using at the same time as other AHAs, BHAs,";
        const applicationWarningSecondary = "Retinol or Vitamin C, use on alternate days."
        const patchTestInfo = "Always patch test - We always recommend to patch test before"
        const patchTestInfoSecondary = "introducing new products into your routine."
        const daytimeRoutine = "Part of your daytime routine."
        const nighttimeRoutine = "Part of your night time routine."

        ctx.font = "22px Madras";
        ctx.textAlign = "center";
        // Draw rect for warnings
        ctx.fillStyle = "black";
        ctx.fillRect(120, (lineY + (i*lineHeight)), 840, 350);
        ctx.fillStyle = "white";
        i += 1;
        // Warning image and text
        ctx.drawImage(this.graphicImages.errorIconWhite, 190, (lineY + (i*lineHeight)) - 16);
        ctx.fillText(applicationWarning, 550, lineY + (i*lineHeight));
        i += 0.5;
        ctx.fillText(applicationWarningSecondary, 550, lineY + (i*lineHeight));
        i += 1;
        // Info image and text
        ctx.drawImage(this.graphicImages.infoIconWhite, 240, lineY + (i*lineHeight) - 15);
        ctx.fillText(patchTestInfo, 550, lineY + (i*lineHeight));
        i += 0.5;
        ctx.fillText(patchTestInfoSecondary, 550, lineY + (i*lineHeight));
        i += 1;
        // Daytime warning
        ctx.drawImage(this.graphicImages.sunIconWhite, 395, lineY + (i*lineHeight) - 17);
        ctx.fillText(daytimeRoutine, 550, lineY + (i*lineHeight));
        i += 1;
        // Nightime warning
        ctx.drawImage(this.graphicImages.moonIconWhite, 390, lineY + (i*lineHeight) - 15);
        ctx.fillText(nighttimeRoutine, 550, lineY + (i*lineHeight));

        this.printCanvas();
    }

    drawProduct (ctx, product, lineX, lineY, lineEnd, lineHeight, i) {
        // Get product name information
        let productName = product.productData.name;
        let productNameDims = ctx.measureText(productName);
        // Create bullet point
        ctx.beginPath();
        ctx.arc(lineX + 20, lineY + (i*lineHeight) - 9, 3, 0, 2 * Math.PI);
        ctx.fill();
        // Add in product name
        ctx.fillText(`${productName}`, lineX + 40, lineY + (i*lineHeight));
        // Add super solutions logo
        if(product.productData.isSuperSolution) {
            ctx.drawImage(this.graphicImages.superSolutions, lineX + productNameDims.width + 110, lineY + (i*lineHeight) - 22);
        }
        // Add application warning icon
        if(product.productData.hasApplicationWarning) {
            ctx.drawImage(this.graphicImages.errorIcon, lineX + productNameDims.width + 60, lineY + (i*lineHeight) - 22);
        }
        // Add product time icons
        ctx.font = "27px Madras 700";
        if(product.productData.day_time) {
            if(product.productData.day_time.includes('am|pm')) {
                ctx.drawImage(this.graphicImages.sunIcon, lineEnd - 60, lineY + (i*lineHeight) - 20);
                ctx.drawImage(this.graphicImages.moonIcon, lineEnd - 20, lineY + (i*lineHeight) - 20);
            }
            else if (product.productData.day_time.includes('am')) {
                ctx.drawImage(this.graphicImages.sunIcon, lineEnd - 20, lineY + (i*lineHeight) - 20);
            } else if (product.productData.day_time.includes('pm')) {
                ctx.drawImage(this.graphicImages.moonIcon, lineEnd - 20, lineY + (i*lineHeight) - 20);
            }
        }
    }

    printCanvas() {
        // Check if browser is desktop safari
        const uA = navigator.userAgent;
        const vendor = navigator.vendor;
        if (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)) {
            // Download the image file as png
            const link = document.createElement('a');
            link.download = 'your-inkey-routine@2x.webp';
            link.href = this.canvas.toDataURL('image/png');
            link.click();
            link.delete;
        } else {
            let imgData = this.canvas.toDataURL('image/png');
            let image = new Image();
            image.download = 'your-inkey-routine@2x.webp'
            image.title = 'your-inkey-routine@2x.webp'
            image.src = imgData;

            this.element.appendChild(image);
        }
    }
}
