<template>
    <div :class="{ 'is-active': isActive }" class="overlay">
        <div class="overlay__inner">
            <div class="overlay__content overlay__content--full overlay__content--center overlay__content--scroll">
                <div class="overlay__grid">
                    <div 
                        v-for="(answer, index) in answerData" 
                        :key="index" 
                        class="overlay__grid-item overlay__grid-item--full-mob"
                        :class="{'overlay__grid-item--center' : checkLength == 1 }"
                    >
                        <h2 class="margin-bot-sml">
                            {{ answer.title }}
                        </h2>
                        <p v-html="answer.description" />
                    </div>
                </div>
                <button
                    type="button"
                    class="button-reset overlay__close-icon"
                    @click="closeOverlay()">
                        <span class="visuallyhidden">
                            Close
                        </span>
                </button>
            </div>
        </div>
        <button
            type="button"
            class="button-reset overlay__close-full"
            @click="closeOverlay()">
            <span class="visuallyhidden">
                Close
            </span>
        </button>
    </div>
</template>

<script>
    export default {
        data: () => {
            return {
                emailSuccess: false
            }
        },
        computed: {
            checkLength() {
                let index = 0;
                this.answerData.forEach(answer => {
                    // Map skin type to mappedAnswers object
                    if (answer.description) {
                        index++;
                    }
                });
                return index;
            }
        },
        props: {
            isActive: {
                type: Boolean,
                required: true
            },
            answerData: {
                type: Array,
                required: true
            }
        },
        methods: {
            closeOverlay() {
                this.$emit('closeOverlay')
            }
        }
    }
</script>